import { useState, useEffect, useRef } from "react";

// Source: https://stackoverflow.com/a/45323523
export default function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    document.addEventListener("touchstart", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      document.removeEventListener("touchstart", handleClickOutside, false);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}
