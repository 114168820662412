import React from "react";
import { Separator, MenuListItem, TextInput } from "react95";
import AppWindow from "../components/AppWindow";
import ButtonMenu from "../components/ButtonMenu";

const Notepad = (props) => {
  const [textContent, setTextContent] = React.useState(
    props.initialContent || ""
  );
  const [wordWrap, setWordWrap] = React.useState(false);
  const textInputRef = React.useRef(null);

  const handleChange = (value) => {
    setTextContent(value);
    props.onChange && props.onChange(value);
  };

  const TITLE_SUFFIX = " - Notepad";
  const title = props.title || `Untitled${TITLE_SUFFIX}`;

  // ---------------------------------------------------------------------------
  // Menu button handlers
  // ---------------------------------------------------------------------------

  // File -> New: Reset document
  const handleClickFileNew = () => handleChange("");

  // File -> Save: Download document as plaintext
  const handleClickFileSave = () => {
    const downloadLink = document.createElement("a");
    const data =
      "data:text/plain;charset=utf-8," + encodeURIComponent(textContent);
    const filename = props.title.endsWith(TITLE_SUFFIX)
      ? props.title.substring(0, props.title.length - TITLE_SUFFIX.length)
      : props.title;
    downloadLink.href = data;
    downloadLink.download = filename;
    downloadLink.target = "_blank";
    try {
      document.body.appendChild(downloadLink);
      downloadLink.click();
    } catch (error) {
      console.error(error);
    } finally {
      document.body.removeChild(downloadLink);
    }
  };

  // File -> Print: Open browser print dialog
  // HACK! Copies text into new div displayed only on "@media print" while
  // setting "display: none;" on everything else.
  // TODO: Currently undoes these changes on unmount, could be better...
  const handleClickFilePrint = () => {
    document.getElementById("root").classList.add("printing-hidden");
    let el = document.getElementById("printing-only");
    if (!el) {
      el = document.createElement("div");
      el.setAttribute("id", "printing-only");
      el.setAttribute("style", "display: none;");
      el.classList.add("notepad-print");
      document.body.appendChild(el);
    }
    el.innerText = textInputRef.current.value;
    window.print();
  };
  React.useEffect(() => {
    return () => {
      document.getElementById("root").classList.remove("printing-hidden");
      const el = document.getElementById("printing-only");
      if (el) {
        el.remove();
      }
    };
  }, []);

  // Edit -> Select All: Select all text
  const handleClickEditSelectAll = () => {
    textInputRef.current.select();
  };

  // Edit -> Word Wrap: Toggle word wrap
  const handleClickEditWordWrap = () => {
    setWordWrap(!wordWrap);
  };

  const buttonMenuProps = {
    buttonClass: "window-toolbar-button",
    activeButtonClass: "window-toolbar-active-button",
    buttonProps: {
      size: "sm",
      variant: "menu",
      disabled: props.disabled,
    },
  };

  return (
    <AppWindow
      title={title}
      windowIcon="notepad2"
      width={720}
      contentStyle={{ padding: 0 }}
      toolbar={
        <>
          <ButtonMenu buttonText="File" {...buttonMenuProps}>
            <MenuListItem size="sm" onClick={handleClickFileNew}>
              New
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Open…
            </MenuListItem>
            <MenuListItem size="sm" onClick={handleClickFileSave}>
              Save
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Save As…
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Page Setup…
            </MenuListItem>
            <MenuListItem size="sm" onClick={handleClickFilePrint}>
              Print
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" onClick={props.onClose}>
              Exit
            </MenuListItem>
          </ButtonMenu>
          <ButtonMenu buttonText="Edit" {...buttonMenuProps}>
            <MenuListItem size="sm" disabled>
              Undo
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Cut
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Copy
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Paste
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Delete
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" onClick={handleClickEditSelectAll}>
              Select All
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Time/Date
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" onClick={handleClickEditWordWrap}>
              {wordWrap && <span className="window-toolbar-list-checkmark" />}
              Word Wrap
            </MenuListItem>
          </ButtonMenu>
          <ButtonMenu buttonText="Search" {...buttonMenuProps}>
            <MenuListItem size="sm" disabled>
              Find…
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Find Next
            </MenuListItem>
          </ButtonMenu>
          <ButtonMenu buttonText="Help" {...buttonMenuProps}>
            <MenuListItem size="sm" disabled>
              Help Topics
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              About Notepad
            </MenuListItem>
          </ButtonMenu>
        </>
      }
      {...props}
    >
      <TextInput
        ref={textInputRef}
        className={`notepad-textfield ${
          wordWrap ? "" : "notepad-textfield-nowrap"
        }`}
        value={textContent}
        disabled={!!props.disabled}
        fullWidth
        multiline="true"
        spellCheck="false"
        autoComplete="false"
        autoCorrect="false"
        autoCapitalize="false"
        onChange={(e) => handleChange(e.target.value)}
      />
    </AppWindow>
  );
};

export default Notepad;
