import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { styleReset } from "react95";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import original from "react95/dist/themes/original";
import ms_sans_serif from "react95/dist/fonts/ms_sans_serif.woff2";
import ms_sans_serif_bold from "react95/dist/fonts/ms_sans_serif_bold.woff2";
import Boot from "./Boot.js";
import BiosMenu, { BiosMenuId } from "./boot/BiosMenu.js";
import DesktopEnvironment from "./DesktopEnvironment.js";
import {
  SPAWN_DELAY_MS,
  SCREENSAVER_IDLE_MS,
  BIOS_MENU_FADE_OUT_DELAY_MS,
  RSVP_URL,
} from "./utils/constants.js";
import { hasBooted } from "./utils/state.js";

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
`;

// See BiosMenu.BiosMenuId
const BIOS_MENU_OPTIONS = Object.freeze({
  [BiosMenuId.SAVE_AND_EXIT]: {
    subtext: "Enter the site",
    description: "Where do you want to go today?™",
    action: { route: "/pc" },
  },
  [BiosMenuId.EXIT]: {
    subtext: "Link to RSVP",
    description: "Just show me the RSVP form",
    action: { redirect: RSVP_URL },
  },
});

function App() {
  const [booted, setBooted] = React.useState(hasBooted());
  const [shutdownAction, setShutdownAction] = React.useState(0);

  const handleBootChange = (flag, action) => {
    setBooted(flag);
    if (Number.isInteger(action)) {
      // see ShutdownDialog.ShutdownOptions
      setShutdownAction(action);
    }
  };

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={original}>
        <BrowserRouter>
          <Routes>
            {/* `/`: Load into BIOS menu by default */}
            <Route
              path="/"
              element={
                <BiosMenu
                  menuConfig={BIOS_MENU_OPTIONS}
                  fadeOutDelayMs={BIOS_MENU_FADE_OUT_DELAY_MS}
                />
              }
            />

            {/* `/pc`: Boot into operating system */}
            <Route
              path="/pc"
              element={
                <>
                  {booted ? (
                    <DesktopEnvironment
                      onBootChange={handleBootChange}
                      spawnDelayMs={SPAWN_DELAY_MS}
                      screensaverIdleMs={SCREENSAVER_IDLE_MS}
                    />
                  ) : (
                    <Boot
                      onBootChange={handleBootChange}
                      shutdownAction={shutdownAction}
                    />
                  )}
                  {shutdownAction > 0 && (
                    <audio
                      src={`${process.env.PUBLIC_URL}/win/shutdown.mp3`}
                      autoPlay
                    />
                  )}
                </>
              }
            />

            {/* Redirect all other paths to `/` */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
