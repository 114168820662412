import React from "react";
import { Frame } from "react95";
import AppWindow from "../components/AppWindow";

const Help = (props) => {
  const { title, render, onOpen } = props;
  return (
    <AppWindow
      title={title || "What's New"}
      windowIcon="helpfile"
      width={480}
      contentStyle={{ padding: 0 }}
      {...props}
    >
      <Frame className="help-frame" variant="field">
        {render ? render(onOpen) : null}
      </Frame>
    </AppWindow>
  );
};

export default Help;
