import React from "react";
import { Separator, MenuListItem, Frame } from "react95";
import AppWindow from "../components/AppWindow";
import ButtonMenu from "../components/ButtonMenu";
import { dispatchIframeEvents } from "../utils/iframe.js";

const Solitaire = (props) => {
  const { onEvent, onClose } = props;
  const iframeRef = React.useRef(null);

  const handleIframeLoad = () => {
    // Forward events up (otherwise swallowed by iframe)
    dispatchIframeEvents(iframeRef.current, onEvent);
  };

  // NOTE: source modified to export this function ("window.resetGame=o;")
  const handleRestart = () => {
    if (iframeRef && iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.resetGame();
    }
  };

  const buttonMenuProps = {
    buttonClass: "window-toolbar-button",
    activeButtonClass: "window-toolbar-active-button",
    buttonProps: { size: "sm", variant: "menu" },
  };

  return (
    <AppWindow
      title="Solitaire"
      windowIcon="sol"
      width={690}
      height={540}
      contentStyle={{ padding: 1 }}
      toolbar={
        <>
          <ButtonMenu buttonText="Game" {...buttonMenuProps}>
            <MenuListItem size="sm" onClick={handleRestart}>
              Deal
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Undo
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Deck…
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Options…
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" onClick={onClose}>
              Exit
            </MenuListItem>
          </ButtonMenu>
          <ButtonMenu buttonText="Help" {...buttonMenuProps}>
            <MenuListItem size="sm" disabled>
              Help Topics
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              About Solitaire
            </MenuListItem>
          </ButtonMenu>
        </>
      }
      {...props}
    >
      <Frame variant="well" style={{ display: "flex", flex: "1 1 auto" }}>
        <iframe
          ref={iframeRef}
          title="sol"
          src={`${process.env.PUBLIC_URL}/sol/index.html`}
          style={{ minWidth: 0, minHeight: 0, flex: "1 1 auto" }}
          onLoad={handleIframeLoad}
        />
      </Frame>
    </AppWindow>
  );
};

export default Solitaire;
