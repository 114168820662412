// Attach event listeners to the iframe element which call the given callback.
export function dispatchIframeEvents(iframeEl, cb) {
  const handleEvent = (ev) => {
    // NOTE: can't naively dispatchEvent() because it gets destroyed if this
    // component is destroyed (DOMException)
    //window.parent.document.dispatchEvent(e);
    cb && cb(ev.type);
  };
  const events = [
    "mousemove",
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "mousewheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
    "visibilitychange",
  ];
  events.forEach((ev) => {
    iframeEl.contentWindow.addEventListener(ev, handleEvent);
  });
}
