import { useEffect } from "react";

export default function useOnOutsideClicks(ref, func) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        func && func(event);
      }
    };

    const events = [
      "click",
      "mousedown",
      "dblclick",
      "contextmenu",
      "touchstart",
    ];
    events.forEach((ev) => {
      document.addEventListener(ev, handleClickOutside, true);
    });
    return () => {
      events.forEach((ev) => {
        document.removeEventListener(ev, handleClickOutside, true);
      });
    };
  }, [ref, func]);
}
