import { useState, useEffect, useCallback } from "react";

export default function useContextMenu(containerRef, menuRef) {
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const [showContextMenu, setShowContextMenu] = useState(false);

  // Compute menu position relative to menu and page bounds.
  // - Flip menu horizontally on x-overflow
  // - Bound to bottom on y-overflow (don't flip)
  const computeMenuPosition = (x, y, rect) => {
    const { innerWidth, innerHeight } = window;
    if (x + rect.width > innerWidth) {
      x -= rect.width;
    }
    if (x < 0) {
      x = rect.width < innerWidth ? (innerWidth - rect.width) / 2 : 0;
    }
    if (y + rect.height > innerHeight) {
      y = innerHeight - rect.height - 4 /* pad */;
    }
    if (y < 0) {
      y = rect.height < innerHeight ? (innerHeight - rect.height) / 2 : 0;
    }
    return [x, y];
  };

  // HACK!! Recompute menu position after menuRef changes, which can happen on
  // the initial render (and handleContextMenu() would have seen a bounding rect
  // of size 0). This "works", but results in a visible menu flip.
  useEffect(() => {
    if (showContextMenu && menuRef && menuRef.current) {
      const rect = menuRef.current.getBoundingClientRect();
      if (rect) {
        const [x, y] = computeMenuPosition(xPos, yPos, rect);
        setXPos(x);
        setYPos(y);
      }
    }
    // Disable linter warning... uh... :/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef]);

  // Context menu event handler
  const handleContextMenu = useCallback(
    (event) => {
      if (
        !containerRef ||
        !containerRef.current ||
        containerRef.current.contains(event.target)
      ) {
        event.preventDefault();
        let x = event.pageX;
        let y = event.pageY;
        if (menuRef && menuRef.current) {
          const rect = menuRef.current.getBoundingClientRect();
          if (rect) {
            [x, y] = computeMenuPosition(x, y, rect);
          }
        }
        setXPos(x);
        setYPos(y);
        setShowContextMenu(true);
      }
    },
    [setXPos, setYPos, containerRef, menuRef]
  );

  // Click handler, closes the menu (if open).
  // It is the user's responsibility to consume the click event if the menu
  // should remain open.
  const handleClick = useCallback(() => {
    showContextMenu && setShowContextMenu(false);
  }, [showContextMenu]);

  // Attach/remove event listeners
  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  });

  return { showContextMenu, contextMenuX: xPos, contextMenuY: yPos };
}
