import React from "react";
import { MenuList, MenuListItem, Separator } from "react95";
import useComponentVisible from "../hooks/useComponentVisible.js";

const StartMenuEntry = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [mouseEnterTimeout, setMouseEnterTimeout] = React.useState(null);
  const { entry, isSubEntry, onCmd } = props;

  if (entry.divider === true) {
    return <Separator size="unset" />;
  }

  const handleClick = (e) => {
    if (entry.cmd) {
      onCmd(entry.cmd, entry.args, entry.delay);
    } else if (entry.folder) {
      setIsComponentVisible(true);
    }
  };
  const handleMouseEnter = () => {
    setMouseEnterTimeout(
      setTimeout(() => entry.folder && setIsComponentVisible(true), 500)
    );
  };
  const handleMouseLeave = () => {
    clearTimeout(mouseEnterTimeout);
    // TODO: this should only hide submenu if moving to a DIFFERENT menu entry,
    // instead of always hiding submenu when mouse leaves the entry tree
    setIsComponentVisible(false);
  };

  const renderEntry = (entry, isSubEntry) => {
    if (entry.emptyFolder) {
      return (
        <span className="start-submenu-item-text start-submenu-item-empty">
          (Empty)
        </span>
      );
    }

    return (
      <>
        <img
          className={`start-menu-item-icon ${
            isSubEntry ? "small-icon" : "large-icon"
          }`}
          src={`${process.env.PUBLIC_URL}/icons/${entry.icon}.png`}
          alt=""
          draggable="false"
        />
        <span
          className={
            isSubEntry ? "start-submenu-item-text" : "start-menu-item-text"
          }
        >
          {entry.name}
        </span>
        {Array.isArray(entry.folder) && <span className="start-menu-caret" />}
      </>
    );
  };

  return (
    <MenuListItem
      ref={ref}
      className={`start-menu-item ${isSubEntry ? "start-submenu-item" : ""}`}
      disabled={!!entry.disabled || !!entry.emptyFolder}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {renderEntry(entry, isSubEntry)}
      {isComponentVisible && Array.isArray(entry.folder) && (
        <div className="start-submenu-container">
          <MenuList className="start-submenu">
            {entry.folder.length > 0 ? (
              entry.folder.map((o, i) => (
                <StartMenuEntry
                  key={i}
                  entry={o}
                  isSubEntry={true}
                  onCmd={onCmd}
                />
              ))
            ) : (
              <StartMenuEntry
                entry={{ emptyFolder: true }}
                isSubEntry={true}
                onCmd={onCmd}
              />
            )}
          </MenuList>
        </div>
      )}
    </MenuListItem>
  );
};

export default StartMenuEntry;
