import React from "react";

const STICKERS = ["fry", "shirokuma", "tokage", "wave", "penguin"];

const StickerGadget = (props) => {
  const [iconIndex, setIconIndex] = React.useState(
    Math.floor(Math.random() * STICKERS.length)
  );

  const icon = STICKERS[iconIndex];
  return (
    <div style={{ position: "absolute", right: 8, bottom: 8 }}>
      <img
        style={{ width: 160 }}
        src={`${process.env.PUBLIC_URL}/stickers/${icon}.gif`}
        alt=""
        draggable="false"
        onClick={() => setIconIndex((iconIndex + 1) % STICKERS.length)}
      />
    </div>
  );
};

export default StickerGadget;
