import React from "react";
import { dispatchIframeEvents } from "../utils/iframe.js";

const Screensaver = (props) => {
  const { onEvent } = props;
  const iframeRef = React.useRef(null);

  const handleIframeLoad = () => {
    // Forward events up (otherwise swallowed by iframe)
    dispatchIframeEvents(iframeRef.current, onEvent);
  };

  return (
    <div
      style={{
        zIndex: 99999,
        position: "fixed",
        height: "100%",
        width: "100%",
        display: "flex",
        background: "#000",
        cursor: "none",
      }}
    >
      <iframe
        ref={iframeRef}
        title="screensaver"
        src={`${process.env.PUBLIC_URL}/3d-flowerbox/index.html`}
        onLoad={handleIframeLoad}
        height="auto"
        width="100%"
      />
    </div>
  );
};

export default Screensaver;
