import React from "react";
import { Radio } from "react95";
import PromptDialog from "../components/PromptDialog";

// NOTE: ShutdownAction is 1-indexed
export const ShutdownOptions = Object.freeze([
  ["Shut down the computer?", true],
  ["Restart the computer?", true],
  ["Restart the computer in MS-DOS mode?", false],
  ["Close all programs and log on as a different user?", false],
]);

const ShutdownDialog = (props) => {
  const { onAction } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleAction = () => {
    onAction && onAction(selectedIndex + 1 /* 1-indexed */);
  };

  return (
    <PromptDialog
      title="Shut Down Windows"
      icon="computer"
      onYes={handleAction}
      isModal={true}
      dragDisabled={true}
      {...props}
    >
      <div>
        <span>Are you sure you want to:</span>
        <div className="prompt-radio-vertical">
          {ShutdownOptions.map(([text, enabled], index) => (
            <Radio
              key={index}
              className="prompt-content-radio"
              checked={index === selectedIndex}
              onChange={() => setSelectedIndex(index)}
              value={selectedIndex}
              label={text}
              disabled={!enabled}
            />
          ))}
        </div>
      </div>
    </PromptDialog>
  );
};

export default ShutdownDialog;
