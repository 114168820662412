import welcomeTxt from "./filesystem/welcome.txt.js";
import finalHlp from "./filesystem/final.hlp.js";
import { SAVE_THE_DATE_URL, RSVP_URL } from "./utils/constants.js";

// -----------------------------------------------------------------------------
// Programs
// -----------------------------------------------------------------------------

// All programs/commands
export const CMDS = Object.freeze({
  OPEN: "OPEN",
  IEXPLORE: "IEXPLORE",
  NOTEPAD: "NOTEPAD",
  MSPAINT: "MSPAINT",
  MINESWEEPER: "MINESWEEPER",
  SOLITAIRE: "SOLITAIRE",
  HELP: "HELP",
  RUN: "RUN",
  SHUTDOWN: "SHUTDOWN",

  // User-installed programs
  FAQ: "FAQ",
});

// -----------------------------------------------------------------------------
// Files
// -----------------------------------------------------------------------------

// All files in C:\
export const FILES = Object.freeze({
  "Desktop\\Welcome.txt": { contents: welcomeTxt },
  "Desktop\\Sign Up.lnk": {
    icon: "html",
    cmd: CMDS.IEXPLORE,
    args: [SAVE_THE_DATE_URL],
  },
  "Desktop\\RSVP.lnk": {
    icon: "html",
    cmd: CMDS.IEXPLORE,
    args: [RSVP_URL],
  },
  "Desktop\\FAQ.lnk": { icon: "faq", cmd: CMDS.FAQ },
  "Desktop\\Photo 1.bmp": { url: "photos/photo1.jpg" },
  "Desktop\\Photo 2.bmp": { url: "photos/photo2.jpg" },
  "Desktop\\Photo 3.bmp": { url: "photos/photo3.jpg" },
  "Desktop\\The Internet.lnk": { icon: "explore", cmd: CMDS.IEXPLORE },
  "Desktop\\Minesweeper.lnk": { icon: "winmine", cmd: CMDS.MINESWEEPER },
  "Desktop\\Solitaire.lnk": { icon: "sol", cmd: CMDS.SOLITAIRE },
  "C:\\Windows\\Help\\final.hlp": finalHlp,
});

// Documents list (in start menu, shown in order)
export const DOCUMENTS = Object.freeze([
  "Desktop\\Welcome.txt",
  "Desktop\\Photo 1.bmp",
  "Desktop\\Photo 2.bmp",
  "Desktop\\Photo 3.bmp",
]);

// Desktop shortcuts (shown in order)
export const DESKTOP = Object.freeze([
  "Desktop\\Welcome.txt",
  //"Desktop\\Sign Up.lnk",
  "Desktop\\RSVP.lnk",
  "Desktop\\FAQ.lnk",
  "Desktop\\Photo 1.bmp",
  "Desktop\\Photo 2.bmp",
  "Desktop\\Photo 3.bmp",
  "Desktop\\The Internet.lnk",
  "Desktop\\Minesweeper.lnk",
]);

// Default help file (shown in start menu)
export const HELP_DEFAULT = "C:\\Windows\\Help\\final.hlp";

// ---------------------------------------------------------------------------
// Init sequence
// ---------------------------------------------------------------------------

export const INIT_CMDS = Object.freeze([
  {
    cmd: CMDS.FAQ,
    delay: 800,
  },
  {
    cmd: CMDS.OPEN,
    args: ["C:\\Windows\\Help\\final.hlp"],
    delay: 400,
  },
]);

// -----------------------------------------------------------------------------
// Utils
// -----------------------------------------------------------------------------

// Get file basename from path
export const getBasenameForFile = (path, noExtension) => {
  const f = path.split("\\").slice(-1)[0];
  return noExtension ? f.substring(0, f.lastIndexOf(".")) || f : f;
};

// Get file extension (without ".")
export const getFileExtension = (path) => {
  const idx = path.lastIndexOf(".");
  return idx === -1 ? "" : path.slice(idx + 1);
};

// File type associations
export const getIconForFile = (path) => {
  switch (getFileExtension(path)) {
    case "txt":
      return "notepad2";
    case "bmp":
      return "bmp";
    case "hlp":
      return "helpbook";
    default:
      return "winfile" /*unknown*/;
  }
};

// Shortcut files
export const isShortcut = (path) => {
  return getFileExtension(path) === "lnk";
};
