import React from "react";
import {
  Button,
  Frame,
  Toolbar,
  Window,
  WindowContent,
  WindowHeader,
} from "react95";
import Draggable from "react-draggable";
import useOnOutsideClicks from "../hooks/useOnOutsideClicks.js";
import { SHOULD_BLOCK_CONTEXT_MENU } from "../utils/constants.js";

// CSS z-index values - actual value subtracts "stackIndex" prop
const Z_INDEX_BASE = 500;
const Z_INDEX_MODAL = 10000;

// Default window dimensions
const DEFAULT_WIDTH = 512;
const DEFAULT_HEIGHT = 200;

const AppWindow = (props) => {
  const nodeRef = React.useRef(null);
  useOnOutsideClicks(nodeRef, (event) => {
    if (props.isModal) {
      event.stopPropagation();
      if (
        event.type === "mousedown" ||
        (event.type === "contextmenu" && SHOULD_BLOCK_CONTEXT_MENU)
      ) {
        event.preventDefault();
      }
    } else if (props.popup) {
      // ignore in parent, let popup do its thing
    } else {
      props.onUnfocus && props.onUnfocus(event);
    }
  });

  // TODO animate minimize/maximize via React effect?

  // Maximize window when double-clicking header
  const handleHeaderDoubleClick = () => {
    props.maximizeButton !== false && props.onMaximize && props.onMaximize();
  };

  // TODO implement right-click on window header/toolbar
  const blockHeaderAndToolbarContextMenu = SHOULD_BLOCK_CONTEXT_MENU
    ? (e) => e.preventDefault()
    : null;

  const outerStyle = {
    zIndex: props.isModal ? Z_INDEX_MODAL : Z_INDEX_BASE - props.stackIndex,
  };
  const windowStyle = {
    width: props.width || DEFAULT_WIDTH,
    minHeight: props.height || DEFAULT_HEIGHT,
    ...(props.windowStyle || {}),
  };
  const defaultOffset = props.windowOffset * 8;

  return (
    <>
      <Draggable
        nodeRef={nodeRef}
        handle=".window-header-title"
        defaultPosition={{ x: defaultOffset, y: defaultOffset }}
        onMouseDown={props.onFocus}
        disabled={props.dragDisabled || !!props.popup}
        bounds="parent"
      >
        <div
          className={`window ${props.isModal ? "window-modal" : ""} ${
            props.maximized ? "maximized" : ""
          } ${props.minimized ? "minimized" : ""}`}
          ref={nodeRef}
          style={outerStyle}
        >
          <Window
            className={`window-inner ${props.maximized ? "maximized" : ""}`}
            style={windowStyle}
          >
            <WindowHeader
              active={props.active && !props.popup}
              className="window-header"
              onDoubleClick={handleHeaderDoubleClick}
              onContextMenu={blockHeaderAndToolbarContextMenu}
            >
              {props.windowIcon && (
                <img
                  className="window-header-icon icon"
                  src={`${process.env.PUBLIC_URL}/icons/${props.windowIcon}.png`}
                  alt=""
                  draggable="false"
                />
              )}
              <span
                className="window-header-title ellipsis"
                style={props.titleStyle}
              >
                {props.title}
              </span>
              <div className="window-header-buttons">
                {props.minimizeButton !== false && (
                  <Button
                    className={`minimize-button ${
                      !props.onMinimize ? "disabled" : ""
                    }`}
                    disabled={!props.onMinimize}
                    onClick={props.popup ? null : props.onMinimize}
                  >
                    <span className="minimize-icon" />
                  </Button>
                )}
                {props.maximizeButton !== false && (
                  <Button
                    className={`maximize-button ${
                      !props.onMaximize ? "disabled" : ""
                    }`}
                    disabled={!props.onMaximize}
                    onClick={props.popup ? null : props.onMaximize}
                  >
                    <span className="maximize-icon" />
                  </Button>
                )}
                {props.closeButton !== false && (
                  <Button
                    className={`close-button ${
                      !props.onClose ? "disabled" : ""
                    }`}
                    disabled={!props.onClose}
                    onClick={props.popup ? null : props.onClose}
                  >
                    <span className="close-icon" />
                  </Button>
                )}
              </div>
            </WindowHeader>
            {props.toolbar && (
              <Toolbar
                className="window-toolbar"
                noPadding
                onContextMenu={blockHeaderAndToolbarContextMenu}
              >
                {props.toolbar}
              </Toolbar>
            )}
            <WindowContent
              className="window-content"
              style={props.contentStyle}
            >
              {props.children}
            </WindowContent>
            {props.preFooter}
            {props.footer && (
              <Frame
                className="window-footer ellipsis"
                variant="well"
                style={props.footerStyles}
              >
                {props.footer}
              </Frame>
            )}
          </Window>
        </div>
      </Draggable>
      {props.popup && (
        <AppWindow
          isPopup={true}
          stackIndex={props.stackIndex}
          windowOffset={props.windowOffset}
          active={props.active}
          onFocus={props.onFocus}
          onUnfocus={props.onUnfocus}
          minimizeButton={false}
          maximizeButton={false}
          {...props.popupProps}
        >
          {props.popup}
        </AppWindow>
      )}
    </>
  );
};

export default AppWindow;
