import React from "react";
import { getBasenameForFile, getIconForFile, isShortcut } from "../Files.js";
import Draggable from "react-draggable";

// TODO: contextmenu is broken on mobile
const DesktopEntry = (props) => {
  const { f, entry, draggable, isSelected, onCmd, onMouseDown } = props;
  const nodeRef = React.useRef(null);

  // Single-click to select/unselect the desktop icon
  const handleMouseDown = (e) => {
    if (e.type === "mousedown") {
      e.stopPropagation(); // prevent handleDesktopMouseDown
    }
    onMouseDown && onMouseDown(e);
  };

  // Double-click to run the program
  // NOTE: we are trying to handle both MOUSE and TOUCH events.
  // - 'dblclick' only gets emulated on mobile Firefox??
  // - there is no "double touch" event, so doing this manually;
  //   also need to block the 'dblclick' event on Firefox
  // - 'touchend' isn't firing (??), so hooking into 'touchstart' instead...
  const DOUBLE_TOUCH_MS = 250; // might differ from system-defined dblclick
  const TOUCH_LAUNCH_BLOCK_MS = 400; // attempt to dedupe dblclick and touch
  const [touchStartTs, setTouchStartTs] = React.useState(0);
  const [touchLaunchTimeout, setTouchLaunchTimeout] = React.useState(null);
  const doCmd = () => {
    onCmd && onCmd();
    setTouchLaunchTimeout(
      setTimeout(() => setTouchLaunchTimeout(null), TOUCH_LAUNCH_BLOCK_MS)
    );
  };
  const handleDoubleClick = (e) => {
    if (!touchLaunchTimeout) {
      doCmd();
    }
  };
  const handleTouchStart = (e) => {
    const now = Date.now();
    if (now - touchStartTs > DOUBLE_TOUCH_MS) {
      setTouchStartTs(now);
    } else if (!touchLaunchTimeout) {
      setTouchStartTs(0);
      doCmd();
    }
  };

  const shortcut = isShortcut(f);
  const icon = (shortcut && entry.icon) || getIconForFile(f);
  const basename = getBasenameForFile(f, true);

  return (
    <Draggable
      nodeRef={nodeRef}
      handle=".desktop-entry"
      disabled={!draggable}
      bounds="parent"
      onMouseDown={handleMouseDown}
    >
      <div
        ref={nodeRef}
        data-path={f /* used in place of refs */}
        className={`desktop-entry ${
          isSelected ? "desktop-entry-selected" : ""
        }`}
        onDoubleClick={handleDoubleClick}
        onTouchStart={handleTouchStart}
      >
        <img
          className="small-icon"
          src={`${process.env.PUBLIC_URL}/icons/${icon}.png`}
          alt=""
          draggable="false"
        />
        {isSelected && (
          <img
            className="small-icon desktop-icon-overlay desktop-icon-selected"
            src={`${process.env.PUBLIC_URL}/icons/${icon}.png`}
            alt=""
            draggable="false"
          />
        )}
        {shortcut && (
          <img
            className="small-icon desktop-icon-overlay"
            src={`${process.env.PUBLIC_URL}/icons/shortcut.png`}
            alt=""
            draggable="false"
          />
        )}
        <div className="desktop-entry-text-box">
          <span className="desktop-entry-text">{basename}</span>
        </div>
      </div>
    </Draggable>
  );
};

export default DesktopEntry;
