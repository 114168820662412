const welcome =
`
                         ╓▄▄▄▓▓▓▓▓▓▓▓▓▓▓▓▄▄╖
                   ╖▄▓▓▓▓▀╜              ╙▀▀▓▓▓▄
                ▄▓▓▀╜                         ╙▀▓▓▄
              ▓▓▀                                 ▀▓▓▄
            ▓▓╜                                     ╙▓▓▄
          ▓▓╜                                         ╙▓▓╖
         ▓▓                                              ▓▌
        ▓▓                                               ╙▓▌
       ▓▓                                                 ╙▓▌
       ▓▌                                                   ▓
       ▓▌             ▓▓▌                                   ▓▓
      ▐▓▌                                                    ▓▌
       ▓▓╖                                                   ▓▓
       ▓▓▌╜▀▀▀▀▀▀╜╙                                           ▓
        ▓▓┐                                                   ▓▌
         ▓▓╗                                                  ▓▌
          ▀▓▓╖                                                ▓▌
            ╙▓▓▄                                              ▓▌
               ▀▓▓╖                                           ▓▌
                 ╙▓▌                                          ▓▌
                   ▓                                          ▓▓
                   ▓▌                                         ▓▓
                   ▓▌                                          ▓
                   ▓▌                                          ▓
                   ▓▌                                         ▐▓▌
                   ▓▌                                          ▓▌
                   ▓                                           ▓▓
                   ▓                                           ▓▓
                  ▓▓                                            ▓
                  ▓▌                                            ▓
                  ▓▌                                           ▐▓
                  ▓                                             ▓▌
                 ▓▓                                              ▓
`;

export default welcome;
