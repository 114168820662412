import React from "react";
import { Frame } from "react95";
import AppWindow from "../../components/AppWindow";

const FAQ = (props) => {
  const { title, onOpen } = props;

  const handleRsvpForm = onOpen ? () => onOpen("Desktop\\RSVP.lnk") : null;

  const CONTENTS = Object.freeze({
    title: "(⁠ﾉ⁠◕⁠ᴗ⁠◕⁠)⁠ﾉ⁠*⁠.⁠✧ FAQ ⁠✧.⁠*(⁠◕⁠ᴗ⁠◕⁠)",
    questions: [
      [
        "When do I need to RSVP?",
        <>
          Please complete the{" "}
          <span className="faq-link" onClick={handleRsvpForm}>
            RSVP form
          </span>{" "}
          by February 1, 2024.
        </>,
      ],
      [
        "Will you have pre- and post-wedding activities on other days?",
        <>
          We’re organizing an informal meetup on the evening of Friday,{" "}
          March 1. Please let us know if you’re interested in attending!
        </>,
      ],
      [
        "Will you have a hotel block?",
        <>
          No, but it should be pretty easy to get a hotel room if you need one!
          We want to give people flexibility to do their own thing (visit
          Philly, for instance), so we don’t want to force people into one area.
          <br />
          If you’re interested in getting together with a group of people for an
          Airbnb or other alternative housing, feel free to message us to help
          coordinate.
        </>,
      ],
      [
        "Where is the venue? How do I get there?",
        <>
          The venue address is:
          <p className="faq-indented">
            1201 Pawlings Rd
            <br />
            Audubon, PA 19403
          </p>
          We recommend you take a car to get there, as the nearest transit stop
          is about a mile away. Message one of us if you would like help
          coordinating any carpools. Note: some GPS systems don’t recognize the
          town of Audubon, so try using this address instead:
          <p className="faq-indented">
            1201 Pawlings Rd
            <br />
            <span className="faq-italic">Norristown</span>, PA 19403
          </p>
          There will be ample parking space in front of the Audubon Center. When
          you arrive, walk past the main entrance of the Audubon Center to the
          pavilion entrance in the back. It is about a two-minute trek, and
          there will be a golf cart available to help ferry anyone who needs it.
          There should also be people standing around to help you if you’re
          lost.
        </>,
      ],
      [
        "What amenities exist at the venue?",
        <>
          The venue features two buildings that we will be moving between — the
          pavilion (ceremony and reception) and the barn (cocktail hour).
          Restrooms are available in both locations, but gender-neutral
          bathrooms are located outside the barn only.
          <br />
          The main visitor center is open to the public until 4pm and includes a
          small museum and gift shop. Please note we will not have access to it
          during our event.
        </>,
      ],
      [
        "What’s the event timeline?",
        <>
          The ceremony will begin at 5 p.m. on Saturday, March 2. Cocktail hour
          and dinner will follow, with food and drinks provided throughout. We
          will also have lawn games out during the cocktail hour and reception,
          as well as a fire pit in the evening (weather permitting). The
          reception will end at 10 p.m. at the latest.
        </>,
      ],
      [
        "What’s the dress code?",
        <>
          Elegant casual. No need to get too dressed up, but please avoid
          workout clothes, hiking boots, and the like. Feel free to dress
          fancier if you prefer, but we absolutely don’t want anyone to feel
          pressured to buy something just for this! Catherine will be in pink,
          but we’re not really concerned about clashing 😛
        </>,
      ],
      [
        "What will the weather be like?",
        <>
          The temperature is forecasted to be around 60°F highs on Saturday and
          in the 40s before then. The venue will have central heating, but we
          recommend bringing a coat regardless as we will be moving between
          buildings, and there may be some outdoor activities.
        </>,
      ],
      [
        "What will the food be?",
        <>
          In addition to hors d’oeuvres at cocktail hour, we will have a full
          dinner with appetizers and dessert. To help you select your entree,
          full descriptions of each entree from our caterer are as follows:
          <ul>
            <li>
              <span className="faq-underline">Braised Beef Short Ribs</span>{" "}
              with a orange cardamom braising sauce, farmer's choice of veggies
              and starch
            </li>
            <li>
              <span className="faq-underline">Seared Sea Bass</span> with a
              lemon caper beurre blanc, farmer's choice of veggies and starch
            </li>
            <li>
              <span className="faq-underline">Pumpkin Croquettes</span> with
              quinoa & wild rice pilaf, brussels sprouts roasted with cranberry,
              lemon & hazelnuts, soy maple cream
            </li>
          </ul>
        </>,
      ],
      [
        "Should I bring gifts? Do you have a registry?",
        <>
          We will not have a registry, as we already have everything we need. We
          know many of you will be traveling across the country to attend, so
          just showing up and celebrating with us is all we ask for. Please do
          not bring presents to the ceremony as we will not have a way to bring
          them home with us. Cards and well wishes are very much appreciated,
          though!
        </>,
      ],
    ],
  });

  return (
    <AppWindow
      title={title || "FAQ"}
      windowIcon="faq"
      width={480}
      contentStyle={{ padding: 0 }}
      {...props}
    >
      <Frame className="faq-frame" variant="field">
        <div className="faq-contents">
          <h1 className="faq-title">{CONTENTS.title}</h1>
          {CONTENTS.questions.map(([q, a], index) => (
            <React.Fragment key={index}>
              <p className="faq-heading">{q}</p>
              <div className="faq-answer">{a}</div>
            </React.Fragment>
          ))}
        </div>
      </Frame>
    </AppWindow>
  );
};

export default FAQ;
