import React from "react";
import { CMDS, FILES, DESKTOP } from "../Files.js";
import DesktopEntry from "./DesktopEntry.js";
import DesktopContextMenu from "./DesktopContextMenu.js";
import StickerGadget from "./StickerGadget.js";
import useContextMenu from "../hooks/useContextMenu.js";

const Desktop = (props) => {
  const { onCmd, windowHasFocus } = props;
  const ref = React.createRef();
  const contextMenuRef = React.createRef();
  const { showContextMenu, contextMenuX, contextMenuY } = useContextMenu(
    ref,
    contextMenuRef
  );
  // Selected desktop entries: map<file_path: true>
  const [selectedEntries, setSelectedEntries] = React.useState({});
  // Copy of selectedEntries when the context menu is opened
  const [selectedEntriesForContextMenu, setSelectedEntriesForContextMenu] =
    React.useState({});
  // Dotted rectangle for desktop drag event: {x1, y1, x2, y2}
  const [dragRect, setDragRect] = React.useState(null);

  // Compute if dragRect overlapped any desktop icons and select them
  React.useEffect(() => {
    if (
      dragRect &&
      dragRect.hasOwnProperty("x2") &&
      dragRect.hasOwnProperty("y2") &&
      ref &&
      ref.current &&
      ref.current.children
    ) {
      const { x1, x2, y1, y2 } = dragRect;
      const rect = normalizeRect(x1, y1, x2, y2);

      const newSelectedEntries = { ...selectedEntries };
      [...ref.current.children].forEach((el) => {
        const f = el.getAttribute("data-path"); // set in DesktopEntry
        const elRect = el.getBoundingClientRect();
        if (f && elRect) {
          const overlaps = !(
            rect.x2 < elRect.left ||
            rect.x1 > elRect.right ||
            rect.y2 < elRect.top ||
            rect.y1 > elRect.bottom
          );
          if (overlaps) {
            newSelectedEntries[f] = true;
          } else {
            delete newSelectedEntries[f];
          }
        }
      });
      setSelectedEntries(newSelectedEntries);
    }
    // Disable linter warning... uh... :/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragRect]);

  // Run command
  const handleCmd = (f) => {
    const entry = FILES[f];
    if (entry) {
      onCmd(CMDS.OPEN, [f]);
    }
  };

  // Event handlers
  const handleDesktopMouseDown = (e) => {
    if (e.buttons === 1 || e.buttons === 2) {
      e.preventDefault(); // prevent drag events in layered windows

      if (e.ctrlKey) {
        // Ctrl-click: ignore
      } else {
        // Click: unselect all
        setSelectedEntries({});
      }

      // Start drag rect (need handleDesktopMouseMove)
      const x1 = e.nativeEvent.pageX;
      const y1 = e.nativeEvent.pageY;
      setDragRect({ x1, y1 });
    }
  };
  const handleDesktopMouseMove = (e) => {
    if (dragRect && (e.buttons === 1 || e.buttons === 2)) {
      e.preventDefault(); // prevent drag events in layered windows

      // Update drag rect
      const x2 = e.nativeEvent.pageX;
      const y2 = e.nativeEvent.pageY;
      setDragRect((dragRect) => ({ ...dragRect, x2, y2 }));
    }
  };
  const handleDesktopMouseUp = (e) => {
    // Remove drag rect
    setDragRect(null);
  };
  const handleDesktopKeyDown = (e) => {
    // Enter key: run program(s)
    if (e.key === "Enter") {
      if (!windowHasFocus) {
        Object.keys(selectedEntries).forEach(handleCmd);
      }
    }
  };
  const handleDesktopEntryMouseDown = (f) => (e) => {
    if (e.ctrlKey) {
      // Ctrl-click: add/remove item from selected list
      setSelectedEntries((selectedEntries) => {
        const newSelectedEntries = Object.assign({}, selectedEntries);
        if (newSelectedEntries[f]) {
          delete newSelectedEntries[f];
        } else {
          newSelectedEntries[f] = true;
        }
        return newSelectedEntries;
      });
    } else {
      // Click: single select
      setSelectedEntries({ [f]: true });
    }
  };

  // Return coordinates such that x1 <= x2 and y1 <= y2
  const normalizeRect = (x1, y1, x2, y2) => {
    if (x1 > x2) {
      [x1, x2] = [x2, x1];
    }
    if (y1 > y2) {
      [y1, y2] = [y2, y1];
    }
    return { x1, y1, x2, y2 };
  };

  const renderDragRect = (rectRaw) => {
    if (
      !rectRaw ||
      !rectRaw.hasOwnProperty("x2") ||
      !rectRaw.hasOwnProperty("y2")
    ) {
      return null;
    }

    const { x1, x2, y1, y2 } = rectRaw;
    const r = normalizeRect(x1, y1, x2, y2);
    const style = {
      left: r.x1,
      top: r.y1,
      width: r.x2 - r.x1,
      height: r.y2 - r.y1,
    };
    return <div className="desktop-drag-rect" style={style} />;
  };

  const renderDesktopIcon = (f, idx) => {
    const entry = FILES[f];
    if (!entry) {
      return null;
    }
    const isSelected = selectedEntries.hasOwnProperty(f) && !windowHasFocus;
    return (
      <DesktopEntry
        key={idx}
        f={f}
        entry={entry}
        draggable={true}
        isSelected={isSelected}
        onCmd={() => handleCmd(f)}
        onMouseDown={handleDesktopEntryMouseDown(f)}
      />
    );
  };

  // Context menu handlers
  React.useEffect(() => {
    // Copy selectedEntries when the context menu changes
    if (showContextMenu) {
      setSelectedEntriesForContextMenu(selectedEntries);
    } else {
      setSelectedEntriesForContextMenu({});
    }
    // HACK!! We explicitly don't want to depend on "selectedEntries" here,
    // as we only want to capture changes when the context menu opens/moves.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContextMenu, contextMenuX, contextMenuY]);
  // Menu -> "Open": run command
  const handleContextMenuCmd = () => {
    Object.keys(selectedEntriesForContextMenu).forEach((f) => handleCmd(f));
  };

  return (
    <div
      ref={ref}
      className="desktop-inner"
      onMouseDown={handleDesktopMouseDown}
      onMouseMove={handleDesktopMouseMove}
      onMouseUp={handleDesktopMouseUp}
      onKeyDown={handleDesktopKeyDown}
      tabIndex={-1 /* to capture keyboard events */}
    >
      {DESKTOP.map(renderDesktopIcon)}
      {renderDragRect(dragRect)}
      <StickerGadget />
      {showContextMenu && (
        <div
          ref={contextMenuRef}
          style={{
            position: "fixed",
            top: contextMenuY,
            left: contextMenuX,
            zIndex: 9999,
          }}
        >
          <DesktopContextMenu
            hasItem={Object.keys(selectedEntriesForContextMenu).length > 0}
            onClickOpen={handleContextMenuCmd}
          />
        </div>
      )}
    </div>
  );
};

export default Desktop;
