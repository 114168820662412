import React from "react";
import { Separator, MenuListItem, GroupBox, Button, TextInput } from "react95";
import AppWindow from "../components/AppWindow";
import ButtonMenu from "../components/ButtonMenu";
import { dispatchIframeEvents } from "../utils/iframe.js";
import { getMinesweeperScores, setMinesweeperScores } from "../utils/state.js";

// [width, height, mines]
const DIFFICULTIES = Object.freeze([
  ["Beginner", [9, 9, 10]],
  ["Intermediate", [16, 16, 40]],
  ["Expert", [30, 16, 99]],
]);

// High score consts
const DEFAULT_HIGHSCORE_TIME = 999;
const DEFAULT_HIGHSCORE_NAME = "Anonymous";

// should be 0 (???)
const HACK_WINDOW_PADDING = 1;

const Minesweeper = (props) => {
  const { onEvent, onClose } = props;
  const [difficultyIndex, setDifficultyIndex] = React.useState(0);
  const [showHighScores, setShowHighScores] = React.useState(false);
  const [questionMarkEnabled, setQuestionMarkEnabled] = React.useState(
    true /* must be in sync with game initial value */
  );
  const iframeRef = React.useRef(null);

  // format: {difficultyName: [time, "name"]}
  const [highScores, setHighScores] = React.useState(getMinesweeperScores());
  const highScoresRef = React.useRef(highScores); // avoid stale state

  // format: [difficultyName, time]
  const [pendingHighScore, setPendingHighScore] = React.useState(null);
  const [pendingHighScoreName, setPendingHighScoreName] = React.useState("");

  const handleIframeLoad = () => {
    // Forward events up (otherwise swallowed by iframe)
    dispatchIframeEvents(iframeRef.current, onEvent);

    // Minesweeper API hooks
    if (iframeRef && iframeRef.current && iframeRef.current.contentWindow) {
      const wnd = iframeRef.current.contentWindow;
      wnd.onGameWin = (time, width, height, mines) => {
        // Won - check if this is a new high score
        const difficulty = DIFFICULTIES.find((x) => {
          // NOTE: can't use difficultyIndex due to stale closure
          const [w, h, m] = x[1];
          return width === w && height === h && mines === m;
        });
        if (!difficulty) {
          return; // unknown?
        }
        const difficultyName = difficulty[0];
        const hs = highScoresRef.current;
        if (
          !hs.hasOwnProperty(difficultyName) ||
          time < hs[difficultyName][0]
        ) {
          doSetPendingHighScore(difficultyName, time);
        }
      };
    }
  };

  // Control game via iframe ref
  const doNewGame = (width, height, mines) => {
    if (iframeRef && iframeRef.current && iframeRef.current.contentWindow) {
      const game = iframeRef.current.contentWindow.minesweeper_;
      game.new_game(
        width || game.width,
        height || game.height,
        mines || game.number_mines
      );

      // numbers taken from original source... :x
      iframeRef.current.width =
        24 + 16 * (width || game.width) - HACK_WINDOW_PADDING * 2;
      iframeRef.current.height = 64 + 16 * (height || game.height);
    }
  };
  const handleRestart = () => {
    doNewGame();
  };
  const handleNewLevel = (params, index) => {
    doNewGame(...params);
    setDifficultyIndex(index);
  };
  const toggleQuestionMark = (flag) => {
    if (iframeRef && iframeRef.current && iframeRef.current.contentWindow) {
      const game = iframeRef.current.contentWindow.minesweeper_;
      game.questionmark_enabled = flag;
      setQuestionMarkEnabled(flag);
    }
  };

  // High score functions
  const doSetHighScores = (newScores) => {
    setMinesweeperScores(newScores); // cookie
    setHighScores(newScores); // state
    highScoresRef.current = newScores; // state ref
  };
  const handleResetHighScores = () => {
    doSetHighScores({});
  };
  const handleShowHighScores = (flag) => {
    setShowHighScores(flag);
    props.onPopupChange && props.onPopupChange(flag);
  };
  const doSetPendingHighScore = (difficultyName, time) => {
    setPendingHighScore([difficultyName, time]);
    setPendingHighScoreName(DEFAULT_HIGHSCORE_NAME);
    setShowHighScores(false);
    props.onPopupChange && props.onPopupChange(true);
  };
  const doFinalizePendingHighScore = (playerName) => {
    const [difficultyName, time] = pendingHighScore;
    const newScores = {
      ...highScores,
      [difficultyName]: [time, playerName || DEFAULT_HIGHSCORE_NAME],
    };
    doSetHighScores(newScores);
    setPendingHighScore(null);
    setPendingHighScoreName("");
    setShowHighScores(true);
  };

  const renderCongratulations = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          userSelect: "none",
        }}
      >
        <div style={{ fontSize: "0.9em" }}>
          You have the fastest time for {pendingHighScore[0].toLowerCase()}{" "}
          level.&nbsp;&nbsp;Please type your name:
        </div>
        <div style={{ padding: "8px 0" }}>
          <form
            style={{ width: "100%" }}
            onSubmit={() => doFinalizePendingHighScore(pendingHighScoreName)}
          >
            <TextInput
              style={{ minHeight: "auto" }}
              fullWidth
              value={pendingHighScoreName}
              onChange={(e) => setPendingHighScoreName(e.target.value)}
              onFocus={(e) => e.target.select()}
              autoFocus
            />
          </form>
        </div>
        <div
          style={{ display: "flex", justifyContent: "right", paddingTop: 8 }}
        >
          <Button
            className="choice-button"
            primary
            onClick={() => doFinalizePendingHighScore(pendingHighScoreName)}
          >
            OK
          </Button>
        </div>
      </div>
    );
  };

  const renderHighScores = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          userSelect: "none",
        }}
      >
        <GroupBox label="Fastest Mine Sweepers" style={{ flexGrow: 1 }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
              rowGap: 12,
              fontSize: "0.9em",
            }}
          >
            {DIFFICULTIES.map(([s, _], index) => (
              <React.Fragment key={index}>
                <span>{s}:</span>
                <span>
                  {highScores[s] ? highScores[s][0] : DEFAULT_HIGHSCORE_TIME}{" "}
                  seconds
                </span>
                <span className="ellipsis">
                  {highScores[s] ? highScores[s][1] : DEFAULT_HIGHSCORE_NAME}
                </span>
              </React.Fragment>
            ))}
          </div>
        </GroupBox>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            gap: 6,
            paddingTop: 8,
          }}
        >
          <Button
            className="choice-button"
            onClick={() => handleResetHighScores()}
          >
            Reset Scores
          </Button>
          <Button
            className="choice-button"
            primary
            onClick={() => handleShowHighScores(false)}
          >
            OK
          </Button>
        </div>
      </div>
    );
  };

  const currentPopupProps = {};
  if (pendingHighScore) {
    currentPopupProps.popup = renderCongratulations();
    currentPopupProps.popupProps = {
      title: "Congratulations",
      width: 285,
      height: 170,
      contentStyle: { padding: "16px 12px 8px" },
      onClose: () => doFinalizePendingHighScore(null),
    };
  } else if (showHighScores) {
    currentPopupProps.popup = renderHighScores();
    currentPopupProps.popupProps = {
      title: "Best Times",
      width: 410,
      height: 220,
      contentStyle: { padding: "16px 12px 8px" },
      onClose: () => handleShowHighScores(false),
    };
  }

  const buttonMenuProps = {
    buttonClass: "window-toolbar-button",
    activeButtonClass: "window-toolbar-active-button",
    buttonProps: { size: "sm", variant: "menu" },
  };
  const titleStyle = { fontSize: "0.74em", letterSpacing: "-0.04em" };

  return (
    <AppWindow
      title="Minesweeper"
      windowIcon="winmine"
      width="auto"
      height="auto"
      contentStyle={{ padding: HACK_WINDOW_PADDING }}
      titleStyle={titleStyle /* HACK: avoid overflowing window title */}
      maximizeButton={false /* HACK: avoid overflowing window title */}
      toolbar={
        <>
          <ButtonMenu buttonText="Game" {...buttonMenuProps}>
            <MenuListItem size="sm" onClick={handleRestart}>
              New
            </MenuListItem>
            <Separator size="unset" />
            {DIFFICULTIES.map(([s, params], index) => (
              <MenuListItem
                key={index}
                size="sm"
                onClick={() => handleNewLevel(params, index)}
              >
                {difficultyIndex === index && (
                  <span className="window-toolbar-list-checkmark" />
                )}
                {s}
              </MenuListItem>
            ))}
            <MenuListItem size="sm" disabled>
              Custom…
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem
              size="sm"
              onClick={() => toggleQuestionMark(!questionMarkEnabled)}
            >
              {questionMarkEnabled && (
                <span className="window-toolbar-list-checkmark" />
              )}
              Marks (?)
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              <span className="window-toolbar-list-checkmark" />
              Color
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" onClick={() => handleShowHighScores(true)}>
              Best Times…
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" onClick={onClose}>
              Exit
            </MenuListItem>
          </ButtonMenu>
          <ButtonMenu buttonText="Help" {...buttonMenuProps}>
            <MenuListItem size="sm" disabled>
              Help Topics
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              About Minesweeper
            </MenuListItem>
          </ButtonMenu>
        </>
      }
      {...currentPopupProps}
      {...props}
    >
      <div style={{ display: "flex" }}>
        <iframe
          ref={iframeRef}
          title="winmine"
          src={`${process.env.PUBLIC_URL}/winmine/index.html`}
          style={{ minWidth: 0, minHeight: 0, flex: "1 1 auto" }}
          width={168 - HACK_WINDOW_PADDING * 2}
          height={208}
          onLoad={handleIframeLoad}
        />
      </div>
    </AppWindow>
  );
};

export default Minesweeper;
