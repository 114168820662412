import React from "react";
import { AppBar, Button, Toolbar } from "react95";
import { useIdleTimer } from "react-idle-timer";
import StartMenu from "./components/StartMenu.js";
import Desktop from "./components/Desktop.js";
import Dock from "./components/Dock.js";
import AlertDialog from "./components/AlertDialog.js";
import Screensaver from "./components/Screensaver.js";
import InternetExplorer from "./programs/InternetExplorer.js";
import Minesweeper from "./programs/Minesweeper.js";
import Solitaire from "./programs/Solitaire.js";
import Notepad from "./programs/Notepad.js";
import Paint from "./programs/Paint.js";
import Help from "./programs/Help.js";
import ShutdownDialog from "./programs/ShutdownDialog.js";
import FAQ from "./programs/user/FAQ.js";
import { SHOULD_BLOCK_CONTEXT_MENU } from "./utils/constants.js";
import { setBootComplete } from "./utils/state.js";
import {
  CMDS,
  FILES,
  HELP_DEFAULT,
  INIT_CMDS,
  getFileExtension,
  getBasenameForFile,
} from "./Files.js";

const DesktopEnvironment = (props) => {
  const { onBootChange, spawnDelayMs, screensaverIdleMs } = props;

  // ---------------------------------------------------------------------------
  // PID management
  // ---------------------------------------------------------------------------

  // Current program list: map<pid(int), Program>
  // where "Program" has keys:
  // - component: React.Component
  // - args: object (passed to component)
  // - toolbar: object{icon: str, title: str} (or null)
  const [pids, setPids] = React.useState({});

  // Generate a new PID based on last PID allocated + 1.
  // This does NOT allocate the PID! (caller's responsibility)
  const PID_START = 2;
  const createPid = (pids) => {
    const sortedPids = new Int32Array(
      Object.keys(pids).map((x) => parseInt(x))
    ).sort();
    if (sortedPids.length === 0) {
      return PID_START;
    } else {
      return sortedPids[sortedPids.length - 1] + 1;
    }
  };

  // Execute a new program (Component) with optional args (Props),
  // adding the program to the pid table.
  //
  // If "singleton" is true (i.e. "single instance" program), the pid table is
  // scanned for any existing component of the same type (functional component
  // "name" field) and will be focused if it exists.
  const exec = (component, args, toolbar, singleton) => {
    const prog = { component, args, toolbar };
    setPids((pids) => {
      if (singleton) {
        const existingPid = Object.entries(pids).find(
          ([_pid, p]) => component.name === p.component.name
        );
        if (existingPid) {
          const pidToInt = parseInt(existingPid[0]); // NOTE: stringified from Object iterator
          focusWindow(pidToInt);
          return pids;
        }
      }

      const pid = createPid(pids);
      return { ...pids, [pid]: prog };
    });
  };

  // Kill a program (pid)
  const kill = (pid) => {
    setPids((pids) => {
      const newPids = { ...pids };
      delete newPids[pid];
      return newPids;
    });
  };

  // ---------------------------------------------------------------------------
  // Window management
  // ---------------------------------------------------------------------------

  // Window management (by pids)
  // - windowStack: order in which windows are stacked, 0 = highest
  // - focusedWindow: the current focused window pid (undefined for none)
  // - toolbarWindows: order in which windows appear in the toolbar, 0 = left
  // - windowState: misc window management state: map<pid,State>
  //     *State: object{minimized: bool, maximized: bool, hasPopup: bool}
  const [windowStack, setWindowStack] = React.useState([]);
  const [focusedWindow, setFocusedWindow] = React.useState(undefined);
  const [toolbarWindows, setToolbarWindows] = React.useState([]);
  const [windowState, setWindowState] = React.useState({});
  const MAX_WINDOW_CNT_OFFSET = 6;

  // Initialize window for a newly-created program (call this AFTER exec())
  const initWindow = (pid, prog) => {
    focusWindow(pid);
    if (prog.toolbar && !toolbarWindows.includes(pid)) {
      setToolbarWindows((toolbarWindows) => [...toolbarWindows, pid]);
    }
  };

  // Focus a window by pid
  const focusWindow = (pid) => {
    setWindowStack((windowStack) => [
      pid,
      ...windowStack.filter((x) => x !== pid),
    ]);
    setFocusedWindow(pid);
    setWindowState((windowState) => {
      const newWindowState = { ...windowState };
      if (!newWindowState.hasOwnProperty(pid)) {
        newWindowState[pid] = {};
      }
      newWindowState[pid].minimized = false;
      return newWindowState;
    });
  };

  // Unfocus a window by pid (if focused)
  const unfocusWindow = (pid) => {
    if (focusedWindow === pid) {
      setFocusedWindow(undefined);
    }
  };

  // Close a window and destroy the pid,
  // then focus the next stacked window (if any)
  const closeWindow = (pid) => {
    kill(pid);
    setToolbarWindows((toolbarWindows) =>
      toolbarWindows.filter((x) => x !== pid)
    );
    const newWindowStack = windowStack.filter((x) => x !== pid);
    setWindowStack(newWindowStack);
    const newFocusCandidates = newWindowStack.filter(
      (x) => !windowState[x] || !windowState[x].minimized
    );
    setFocusedWindow(
      newFocusCandidates.length > 0 ? newFocusCandidates[0] : undefined
    );
    setWindowState((windowState) => {
      const newWindowState = { ...windowState };
      delete newWindowState[pid];
      return newWindowState;
    });
    setPidsInited((pidsInited) => {
      const newPidsInited = { ...pidsInited };
      delete newPidsInited[pid];
      return newPidsInited;
    });
  };

  // Maximize/unmaximize a window
  const toggleMaximizeWindow = (pid, flag) => {
    setWindowState((windowState) => {
      const newWindowState = { ...windowState };
      if (!newWindowState.hasOwnProperty(pid)) {
        newWindowState[pid] = {};
      }
      newWindowState[pid].maximized = flag;
      return newWindowState;
    });
  };

  // Minimize a window and focus the next stacked window (if any)
  const minimizeWindow = (pid) => {
    const state = windowState[pid];
    if (state) {
      if (state.minimized || state.hasPopup /* blocks minimize */) {
        return;
      }
    }
    setWindowState((windowState) => {
      const newWindowState = { ...windowState };
      if (!newWindowState.hasOwnProperty(pid)) {
        newWindowState[pid] = {};
      }
      newWindowState[pid].minimized = true;
      return newWindowState;
    });
    if (focusedWindow === pid) {
      // Find the next non-minimized window in the window stack
      const newFocusCandidates = windowStack.filter(
        (x) => x !== pid && (!windowState[x] || !windowState[x].minimized)
      );
      if (newFocusCandidates.length > 0) {
        focusWindow(newFocusCandidates[0]);
      } else {
        unfocusWindow(pid);
      }
    }
  };

  // Set whether a window has a popup
  const setPopupWindowState = (pid, flag) => {
    setWindowState((windowState) => {
      const newWindowState = { ...windowState };
      if (!newWindowState.hasOwnProperty(pid)) {
        newWindowState[pid] = {};
      }
      newWindowState[pid].hasPopup = flag;
      return newWindowState;
    });
  };

  // Focus a window if unfocused, or minimize if already focused
  const focusOrUnminimizeWindow = (pid) => {
    if (focusedWindow === pid) {
      minimizeWindow(pid);
    } else {
      focusWindow(pid);
    }
  };

  // HACK: init windows by hooking into "pids" change so that pid creation can
  // be run fully async (without needing to return pid synchronously),
  // solving the problems with stale state and rapid exec() calls
  const [pidsInited, setPidsInited] = React.useState({}); // map<pid:bool>
  React.useEffect(() => {
    const newPidsInited = {};
    Object.entries(pids).forEach(([pid, prog]) => {
      const pidToInt = parseInt(pid); // NOTE: stringified from Object iterator
      if (!pidsInited.hasOwnProperty(pidToInt)) {
        initWindow(pidToInt, prog);
      }
      newPidsInited[pidToInt] = true;
    });
    setPidsInited(newPidsInited);
    // Disable linter warning... uh... :/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pids]);

  // ---------------------------------------------------------------------------
  // Programs
  // ---------------------------------------------------------------------------

  // spawnCmd() with delay (simulate slow PCs ^_^)
  const [loadingCmdCount, setLoadingCmdCount] = React.useState(0);
  const spawnCmdWithDelay = (cmd, args, delay) => {
    const delayMs = Number.isInteger(delay) ? delay : spawnDelayMs || 0;
    if (delayMs <= 0) {
      return spawnCmd(cmd, args);
    }
    const jitterMs = Math.floor(Math.random() * 50);
    setLoadingCmdCount((loadingCmdCount) => loadingCmdCount + 1);
    setTimeout(() => {
      // TODO clean up timeouts on unmount (how??)
      spawnCmd(cmd, args);
      setLoadingCmdCount((loadingCmdCount) => Math.max(loadingCmdCount - 1, 0));
    }, delayMs + jitterMs);
  };

  // Handle commands, i.e. execute programs
  const spawnCmd = (cmd, args) => {
    switch (cmd) {
      case CMDS.OPEN: {
        if (!args || args.length < 1 || !FILES.hasOwnProperty(args[0])) {
          return spawnAlert(
            "warn",
            "Problem with Shortcut",
            "The drive or network connection that the shortcut refers to is unavailable. " +
              "Make sure that the disk is properly inserted or the network resource is available, and then try again."
          );
        }
        const path = args[0];
        const fileData = FILES[path];
        const basename = getBasenameForFile(path, true);
        const ext = getFileExtension(path);
        switch (ext) {
          case "txt": {
            return exec(
              Notepad,
              {
                title: `${basename} - Notepad`,
                initialContent: fileData.contents,
              },
              { icon: "notepad2", title: `${basename} - Notepad` }
            );
          }
          case "bmp": {
            return exec(
              Paint,
              { title: `${basename}.${ext} - Paint`, loadImage: fileData.url },
              { icon: "mspaint", title: `${basename}.${ext} - Paint` }
            );
          }
          case "hlp": {
            return exec(
              Help,
              {
                title: fileData.title,
                render: fileData.render,
                onOpen: (f) => spawnCmdWithDelay(CMDS.OPEN, [f]),
              },
              { icon: "helpfile", title: fileData.title }
            );
          }
          case "lnk": {
            return spawnCmd(fileData.cmd, fileData.args);
          }
          default:
            return spawnAlert(
              "warn",
              "Program Not Found",
              `Windows cannot find the program needed for opening files of type '${ext.toUpperCase()} File'.`
            );
        }
      }
      case CMDS.IEXPLORE: {
        const defaultUrl = args && args.length > 0 ? args[0] : undefined;
        return exec(
          InternetExplorer,
          {
            title: "Microsoft Internet Explorer",
            onNewWindow: () => spawnCmdWithDelay(CMDS.IEXPLORE),
            onAlert: spawnAlert,
            defaultUrl,
            useWayback: !defaultUrl,
          },
          { icon: "html", title: "Microsoft Internet Explorer" }
        );
      }
      case CMDS.HELP: {
        const path =
          !args || args.length < 1 || !FILES.hasOwnProperty(args[0])
            ? HELP_DEFAULT
            : args[0];
        const fileData = FILES[path];
        return exec(
          Help,
          {
            title: fileData.title,
            render: fileData.render,
            onOpen: (f) => spawnCmdWithDelay(CMDS.OPEN, [f]),
          },
          { icon: "helpfile", title: fileData.title }
        );
      }
      case CMDS.NOTEPAD: {
        return exec(
          Notepad,
          { title: "Untitled - Notepad" },
          { icon: "notepad2", title: "Untitled - Notepad" }
        );
      }
      case CMDS.MSPAINT: {
        return exec(
          Paint,
          { title: "Untitled - Paint" },
          { icon: "mspaint", title: "Untitled - Paint" }
        );
      }
      case CMDS.MINESWEEPER: {
        return exec(
          Minesweeper,
          { title: "Minesweeper" },
          { icon: "winmine", title: "Minesweeper" }
        );
      }
      case CMDS.SOLITAIRE: {
        return exec(
          Solitaire,
          { title: "Solitaire" },
          { icon: "sol", title: "Solitaire" }
        );
      }
      case CMDS.SHUTDOWN: {
        return exec(ShutdownDialog, {
          onAction: (action) => {
            setBootComplete(false);
            onBootChange && onBootChange(false, action);
          },
        });
      }
      case CMDS.FAQ: {
        return exec(
          FAQ,
          { title: "FAQ", onOpen: (f) => spawnCmdWithDelay(CMDS.OPEN, [f]) },
          { icon: "faq", title: "FAQ" },
          true /* singleton */
        );
      }
      case CMDS.RUN:
      default:
        // TODO
        return spawnAlert(
          "error",
          cmd.toLowerCase(),
          `Cannot find the file '${cmd.toLowerCase()}' (or one of its components). ` +
            `Make sure the path and filename are correct and that all required libraries are available.`
        );
    }
  };
  // Treat alerts like programs (HACK)
  const spawnAlert = (icon, title, textContent) => {
    exec(AlertDialog, { icon, title, textContent });
  };

  // Render screensaver if idle
  const [isIdle, setIsIdle] = React.useState(false);
  const idleTimerMethods = useIdleTimer(
    Number.isInteger(screensaverIdleMs) && screensaverIdleMs > 0
      ? {
          onIdle: () => setIsIdle(true),
          onActive: () => setIsIdle(false),
          timeout: screensaverIdleMs,
        }
      : { startManually: true /* effectively disabled */ }
  );
  const handleWindowEvent = (_type) => {
    idleTimerMethods.activate(); // reset timer and fire onActive() if idle
  };

  // Render program window
  const renderWindow = (pid, prog) => {
    const pidToInt = parseInt(pid); // NOTE: stringified from Object iterator
    const stackIndex = windowStack.indexOf(pidToInt);
    const windowOffset = (pid - PID_START) % MAX_WINDOW_CNT_OFFSET;
    const state = windowState[pidToInt];
    const maximized = state && state.maximized;
    const minimized = state && state.minimized;
    const handleUnfocus = (e) => {
      if (e.target && e.target.classList.contains("appbar-window-button")) {
        // ignore unfocus due to clicking on toolbar button
        // (defer to event that will trigger there, like focus/minimize)
      } else {
        unfocusWindow(pidToInt);
      }
    };
    return (
      <prog.component
        key={pid}
        active={pidToInt === focusedWindow}
        stackIndex={stackIndex}
        windowOffset={windowOffset}
        maximized={!!maximized}
        minimized={!!minimized}
        onFocus={() => focusWindow(pidToInt)}
        onUnfocus={handleUnfocus}
        onClose={() => closeWindow(pidToInt)}
        onMaximize={() => toggleMaximizeWindow(pidToInt, !maximized)}
        onMinimize={() => minimizeWindow(pidToInt)}
        onEvent={handleWindowEvent}
        onPopupChange={(flag) => setPopupWindowState(pidToInt, flag)}
        {...(prog.args || {})}
      />
    );
  };

  // Render toolbar button
  const renderToolbarButton = (pid) => {
    const opts = pids[pid].toolbar;
    const active = pid === focusedWindow;
    return (
      <Button
        key={pid}
        className="appbar-window-button"
        onClick={() => focusOrUnminimizeWindow(pid)}
        active={active}
      >
        {opts.icon && (
          <img
            className="window-header-icon icon"
            src={`${process.env.PUBLIC_URL}/icons/${opts.icon}.png`}
            alt=""
            draggable="false"
          />
        )}
        <span
          className={`appbar-window-button-title ellipsis ${
            active ? "appbar-window-button-title-active" : ""
          }`}
        >
          {opts.title || ""}
        </span>
      </Button>
    );
  };

  // ---------------------------------------------------------------------------
  // Init sequence
  // ---------------------------------------------------------------------------

  // Advance through INIT_CMDS via setTimeout()
  const [initIndex, setInitIndex] = React.useState(0);
  React.useEffect(() => {
    if (initIndex < INIT_CMDS.length) {
      const id = setTimeout(() => {
        spawnCmdWithDelay(INIT_CMDS[initIndex].cmd, INIT_CMDS[initIndex].args);
        setInitIndex(initIndex + 1);
      }, INIT_CMDS[initIndex].delay);
      return () => clearTimeout(id);
    } else {
      return () => {};
    }
    // Disable linter warning... uh... :/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initIndex]);

  // ---------------------------------------------------------------------------
  // Main render method
  // ---------------------------------------------------------------------------

  // TODO support context menu on Toolbar and Start Menu
  const blockToolbarAndStartContextMenu = SHOULD_BLOCK_CONTEXT_MENU
    ? (e) => e.preventDefault()
    : null;

  return (
    <div className={`container ${loadingCmdCount > 0 ? "loading" : ""}`}>
      <div className="desktop">
        <Desktop onCmd={spawnCmdWithDelay} windowHasFocus={!!focusedWindow} />
        {Object.entries(pids).map(([pid, o]) => renderWindow(pid, o))}
      </div>
      <AppBar className="appbar" fixed="true">
        <Toolbar onContextMenu={blockToolbarAndStartContextMenu}>
          <StartMenu onCmd={spawnCmdWithDelay} />
          <div className="appbar-window-button-container">
            {toolbarWindows.map((pid) => renderToolbarButton(pid))}
          </div>
          <Dock />
        </Toolbar>
      </AppBar>
      {isIdle && <Screensaver onEvent={handleWindowEvent} />}
    </div>
  );
};

export default DesktopEnvironment;
