const content = {
  title: "Welcome",
  render: (handleOpen) => {
    const styles = {
      textbox: {
        display: "flex",
        flexDirection: "column",
        gap: "0.5em",
        textAlign: "center",
      },
      title: {
        fontWeight: "bold",
      },
      date: {
        fontSize: "1.24em",
        letterSpacing: "4px",
      },
      details: {
        fontSize: "0.94em",
        fontStyle: "italic",
      },
      divider: {
        fontSize: "0.68em",
      },
      bottomSection: {
        fontSize: "0.94em",
      },
      contact: {
        color: "blue",
        textDecoration: "underline",
        cursor: "pointer",
      },
    };
    const handleFaq = handleOpen
      ? () => handleOpen("Desktop\\FAQ.lnk")
      : null;
    return (
      <div className="textbox" style={styles.textbox}>
        <h1 style={styles.title}>
          (⁠ﾉ⁠◕⁠ᴗ⁠◕⁠)⁠ﾉ⁠*⁠.⁠✧ SAVE THE DATE ⁠✧.⁠*\(⁠◕⁠ᴗ⁠◕⁠\)
        </h1>
        <p style={styles.date}>
          ⁠<span className="rainbow-text">&#123; March 2nd, 2024 &#125;</span>
        </p>
        <p style={styles.details}>
          for Catherine and Jeffrey's wedding
          <br />
          at the John James Audubon Center
          <br />
          located at 1201 Pawlings Rd, Audubon, PA 19403
        </p>
        <p style={styles.divider}>☆ ☆ ☆</p>
        <p style={styles.bottomSection}>
          Check out the {" "}
          <span style={styles.contact} onClick={handleFaq}>
            FAQ
          </span>
          {" "}for more info!
          <br />
          ⁠✧.⁠*\(⁠◍⁠•⁠ᴗ⁠•⁠◍⁠)/✧*⁠。
        </p>
      </div>
    );
  },
};

export default content;
