import React from "react";
import { MenuList, MenuListItem, Separator } from "react95";
import useComponentVisible from "../hooks/useComponentVisible.js";

const DesktopContextMenu = (props) => {
  const { hasItem, onClickOpen } = props;
  const [flipX, setFlipX] = React.useState(false);
  const menuRef = React.createRef();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [mouseEnterTimeout, setMouseEnterTimeout] = React.useState(null);

  // Flip the submenu if doubling the main menu width would exceed innerWidth
  React.useEffect(() => {
    if (menuRef && menuRef.current) {
      const { innerWidth } = window;
      const rect = menuRef.current.getBoundingClientRect();
      if (rect) {
        setFlipX(rect.x + rect.width * 2 > innerWidth);
      }
    }
  }, [menuRef]);

  // Submenu event handlers
  const handleClick = (e) => {
    e.stopPropagation();
    setIsComponentVisible(true);
  };
  const handleMouseEnter = () => {
    setMouseEnterTimeout(
      setTimeout(() => hasItem && setIsComponentVisible(true), 500)
    );
  };
  const handleMouseLeave = () => {
    clearTimeout(mouseEnterTimeout);
    setIsComponentVisible(false);
  };

  const renderSendToMenu = () => {
    const MENU_ITEMS = [
      ["3½ Floppy (A)", "drive"],
      ["Fax Recipient", "fax"],
      ["Mail Recipient", "mail2"],
      ["My Briefcase", "briefcase"],
    ];
    return (
      <div
        className={`desktop-context-submenu-container ${
          flipX ? "left" : "right"
        }`}
      >
        <MenuList className="desktop-context-submenu">
          {MENU_ITEMS.map(([s, icon], idx) => (
            <MenuListItem key={idx} size="sm" disabled>
              <img
                className="desktop-context-submenu-icon icon"
                src={`${process.env.PUBLIC_URL}/icons/${icon}.png`}
                alt=""
                draggable="false"
              />
              {s}
            </MenuListItem>
          ))}
        </MenuList>
      </div>
    );
  };

  // Menu for selected desktop entry
  const renderMenuForItem = () => {
    return (
      <>
        <MenuListItem size="sm" primary onClick={onClickOpen}>
          Open
        </MenuListItem>
        <Separator className="desktop-context-menu-separator" />
        <MenuListItem
          ref={ref}
          size="sm"
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Send To
          <span className="desktop-context-menu-arrow" />
          {isComponentVisible && renderSendToMenu()}
        </MenuListItem>
        <Separator className="desktop-context-menu-separator" />
        <MenuListItem size="sm" disabled>
          Cut
        </MenuListItem>
        <MenuListItem size="sm" disabled>
          Copy
        </MenuListItem>
        {/* <MenuListItem size="sm" disabled>Paste</MenuListItem> */}
        <Separator className="desktop-context-menu-separator" />
        <MenuListItem size="sm" disabled>
          Create Shortcut
        </MenuListItem>
        <MenuListItem size="sm" disabled>
          Delete
        </MenuListItem>
        <MenuListItem size="sm" disabled>
          Rename
        </MenuListItem>
        <Separator className="desktop-context-menu-separator" />
        <MenuListItem size="sm" disabled>
          Properties
        </MenuListItem>
      </>
    );
  };

  // Menu for desktop (no entries selected)
  const renderDesktopMenu = () => {
    return (
      <>
        <MenuListItem size="sm" disabled>
          Arrange Icons
          <span className="desktop-context-menu-arrow" />
          {/* TODO submenu */}
        </MenuListItem>
        <MenuListItem size="sm" disabled>
          Line up Icons
        </MenuListItem>
        <Separator className="desktop-context-menu-separator" />
        <MenuListItem size="sm" disabled>
          Paste
        </MenuListItem>
        <MenuListItem size="sm" disabled>
          Paste Shortcut
        </MenuListItem>
        <Separator className="desktop-context-menu-separator" />
        <MenuListItem size="sm" disabled>
          New
          <span className="desktop-context-menu-arrow" />
          {/* TODO submenu */}
        </MenuListItem>
        <Separator className="desktop-context-menu-separator" />
        <MenuListItem size="sm" disabled>
          Properties
        </MenuListItem>
      </>
    );
  };

  return (
    <MenuList className="desktop-context-menu" ref={menuRef}>
      {hasItem ? renderMenuForItem() : renderDesktopMenu()}
    </MenuList>
  );
};

export default DesktopContextMenu;
