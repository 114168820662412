import React from "react";
import BootCursor from "./BootCursor.js";

const _join = (...text) => text.join("\n");
const TERM_TEXT = [
  {
    text: "NessieBIOS Version 1.03",
    delay: 800,
  },
  {
    text: "Copyright (C) 1995, San-Y Co., Ltd.",
    delay: 60,
  },
  {
    text: _join(" ", " ", "PENTIUM-S CPU at 180MHz"),
    delay: 2000,
  },
  {
    text: "Memory Test :    16384K OK",
    delay: 800,
  },
  {
    text: _join(" ", "Please Wait..."),
    delay: 2000,
  },
  {
    text: _join(" ", "Keyboard.........Detected"),
    delay: 3000,
  },
  {
    text: "Mouse............Detected",
    delay: 1000,
  },
  {
    text: _join(" ", "Hard Disk 0......Installed ST32140A"),
    delay: 2000,
  },
  {
    text: "CD-ROM Drive.....Installed NEC CD-ROM DRIVE:280",
    delay: 2000,
  },
  {
    text: "Floppy A.........Installed",
    delay: 1500,
  },
  {
    text: _join(" ", " ", "Starting Windows 95..."),
    delay: 2000,
  },
  {
    text: "",
    delay: 2000,
  },
];

const BiosScreen = (props) => {
  const { onComplete, cpuSpeed } = props;

  // Advance through TERM_TEXT via setTimeout()
  const [textIndex, setTextIndex] = React.useState(0);
  React.useEffect(() => {
    if (textIndex < TERM_TEXT.length) {
      const divisor = Number.isInteger(cpuSpeed) && cpuSpeed > 0 ? cpuSpeed : 1;
      const id = setTimeout(() => {
        setTextIndex(textIndex + 1);
      }, TERM_TEXT[textIndex].delay / divisor);
      return () => clearTimeout(id);
    } else {
      onComplete && onComplete();
      return () => {};
    }
  }, [textIndex, onComplete, cpuSpeed]);

  // Click logo repeatedly to skip bios screen
  const [skipBiosCount, setSkipBiosCount] = React.useState(4);
  const handleSkipBios = () => {
    if (skipBiosCount > 0) {
      setSkipBiosCount(skipBiosCount - 1);
    } else {
      onComplete && onComplete();
    }
  };

  // Preload boot graphics
  React.useEffect(() => {
    const bootImg = new Image();
    bootImg.src = `${process.env.PUBLIC_URL}/win/boot.gif`;
  }, []);

  return (
    <>
      <img
        className="boot-logo"
        style={{ visibility: textIndex < 1 ? "hidden" : "" }}
        src={`${process.env.PUBLIC_URL}/logo.png`}
        alt=""
        draggable="false"
        onClick={handleSkipBios}
      />
      <div className="boot-term">
        {TERM_TEXT.slice(0, textIndex).map((o, index) => (
          <p key={index}>{o.text}</p>
        ))}
        <BootCursor noWrapper={true} />
      </div>
    </>
  );
};

export default BiosScreen;
