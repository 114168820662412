import Cookies from "js-cookie";

// Manage boot state
const BOOT_COOKIE_NAME = "boot";
const BOOT_COOKIE_VALUE = "1";
const BOOT_COOKIE_DEFAULT_EXPIRE_DAYS = 1;
export function hasBooted() {
  return Cookies.get(BOOT_COOKIE_NAME) === BOOT_COOKIE_VALUE;
}
export function setBootComplete(flag, expires) {
  if (flag) {
    Cookies.set(BOOT_COOKIE_NAME, BOOT_COOKIE_VALUE, {
      sameSite: "strict",
      expires:
        Number.isInteger(expires) && expires >= 0
          ? expires
          : BOOT_COOKIE_DEFAULT_EXPIRE_DAYS,
    });
  } else {
    Cookies.remove(BOOT_COOKIE_NAME);
  }
}

// Manage default browser state
const DEFAULT_BROWSER_SET_COOKIE_NAME = "default-browser";
const DEFAULT_BROWSER_SET_COOKIE_VALUE = "1";
export function defaultBrowserSet() {
  return (
    Cookies.get(DEFAULT_BROWSER_SET_COOKIE_NAME) ===
    DEFAULT_BROWSER_SET_COOKIE_VALUE
  );
}
export function setDefaultBrowserSet(flag) {
  if (flag) {
    // session cookie (no "expires")
    Cookies.set(
      DEFAULT_BROWSER_SET_COOKIE_NAME,
      DEFAULT_BROWSER_SET_COOKIE_VALUE,
      {
        sameSite: "strict",
      }
    );
  } else {
    Cookies.remove(DEFAULT_BROWSER_SET_COOKIE_NAME);
  }
}

// Manage Minesweeper high scores
const MINESWEEPER_COOKIE_NAME = "minesweeper-scores";
export function getMinesweeperScores() {
  const enc = Cookies.get(MINESWEEPER_COOKIE_NAME);
  try {
    return enc ? JSON.parse(enc) : {};
  } catch (_) {
    return {};
  }
}
export function setMinesweeperScores(newScores) {
  if (newScores) {
    Cookies.set(MINESWEEPER_COOKIE_NAME, JSON.stringify(newScores), {
      sameSite: "strict",
      expires: 400 /* Chrome limit */,
    });
  } else {
    Cookies.remove(MINESWEEPER_COOKIE_NAME);
  }
}
