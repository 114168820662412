import React from "react";
import { Button } from "react95";
import AppWindow from "./AppWindow";

const AlertDialog = (props) => {
  const { icon, title, textContent } = props;

  return (
    <AppWindow
      title={title}
      width={560}
      height={150}
      contentStyle={{ padding: 0 }}
      maximizeButton={false}
      minimizeButton={false}
      {...props}
    >
      <div>
        <div className="alert-content">
          <img
            className="xlarge-icon"
            src={`${process.env.PUBLIC_URL}/icons/${icon}.png`}
            alt=""
            draggable="false"
          />
          <div className="alert-text">{textContent}</div>
        </div>
        <div className="alert-button-container">
          <Button className="choice-button" autoFocus onClick={props.onClose}>
            OK
          </Button>
        </div>
        <audio src={`${process.env.PUBLIC_URL}/win/error.mp3`} autoPlay />
      </div>
    </AppWindow>
  );
};

export default AlertDialog;
