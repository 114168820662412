import React from "react";
import { Button, MenuList } from "react95";

const ControlledButtonMenu = (props) => {
  const {
    menuRef,
    isOpen,
    setIsOpen,
    keepOpenAfterInnerClick,
    buttonClass,
    activeButtonClass,
    buttonProps,
    buttonText,
    flipped,
    menuClass,
    children,
  } = props;

  const handleMenuListClick = keepOpenAfterInnerClick
    ? null
    : () => setIsOpen(false);
  return (
    <div ref={menuRef} className="button-menu-container">
      <Button
        className={`${buttonClass || ""} ${
          isOpen ? activeButtonClass || "" : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
        active={isOpen}
        {...buttonProps}
      >
        {buttonText}
      </Button>
      {isOpen && (
        <MenuList
          className={`button-menu ${
            flipped ? "button-menu-up" : "button-menu-down"
          } ${menuClass || ""}`}
          onClick={handleMenuListClick}
        >
          {children}
        </MenuList>
      )}
    </div>
  );
};

export default ControlledButtonMenu;
