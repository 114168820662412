import React from "react";
import { Button } from "react95";
import AppWindow from "./AppWindow";

const PromptDialog = (props) => {
  const { icon, children, onYes, onClose } = props;

  return (
    <AppWindow
      width={430}
      contentStyle={{ padding: 0 }}
      maximizeButton={false}
      minimizeButton={false}
      {...props}
    >
      <div>
        <div className="prompt-content">
          {icon && (
            <img
              className="xlarge-icon"
              src={`${process.env.PUBLIC_URL}/icons/${icon}.png`}
              alt=""
              draggable="false"
            />
          )}
          {children}
        </div>
        <div className="window-footer-button-container">
          <Button className="choice-button" primary onClick={onYes}>
            Yes
          </Button>
          <Button className="choice-button" onClick={onClose}>
            No
          </Button>
        </div>
      </div>
    </AppWindow>
  );
};

export default PromptDialog;
