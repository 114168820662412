import React from "react";
import { useNavigate } from "react-router-dom";
import BootCursor from "./BootCursor.js";

// TODO fill in as needed based on MENU_OPTIONS
export const BiosMenuId = Object.freeze({
  SAVE_AND_EXIT: "SAVE_AND_EXIT",
  EXIT: "EXIT",
});

const LOADING_DELAY_MS = 600;
const MENU_TITLE =
  "NessieBIOS Setup Utility - Copyright (C) 1995 San-Y Co., Ltd.";
const MENU_OPTIONS = [
  { text: "Standard CMOS Features", menu: true },
  { text: "Advanced BIOS Features", menu: true },
  { text: "Advanced Chipset Features", menu: true },
  { text: "Integrated Peripherals", menu: true },
  { text: "Power Management Setup", menu: true },
  { text: "PnP/PCI Configurations", menu: true },
  { text: "PC Health Status", menu: true },
  { text: "Frequency/Voltage Control", menu: true },
  { text: "Load Fail-Safe Defaults", menu: false },
  { text: "Load Optimized Defaults", menu: false },
  { text: "Set Supervisor Password", menu: false },
  { text: "Set User Password", menu: false },
  {
    id: BiosMenuId.SAVE_AND_EXIT,
    text: "Save & Exit Setup",
    menu: false,
  },
  {
    id: BiosMenuId.EXIT,
    text: "Exit Without Saving",
    menu: false,
  },
];
const MENU_DESCRIPTION_DEFAULT = "¯\\_(ツ)_/¯";
const MENU_LEGEND = [
  "Esc : Quit",
  "F10 : Save & Exit Setup",
  "↑ ↓ → ← : Select Item",
];

const BiosMenu = (props) => {
  const { menuConfig, fadeOutDelayMs } = props;

  // Merge MENU_OPTIONS with menuConfig, and add array index as field
  const menuOptions = MENU_OPTIONS.map((o, index) => ({
    ...o,
    index,
    ...((menuConfig ? menuConfig[o.id || ""] : null) || {}),
  }));
  const menuSplitIdx = Math.ceil(menuOptions.length / 2);
  const legendSplitIdx = Math.ceil(MENU_LEGEND.length / 2);

  // Handle actions given in `menuConfig`
  // - "route": Navigate via react-router-dom
  // - "redirect": Open new tab
  const navigate = useNavigate();
  const doAction = (index) => {
    const o = menuOptions[index];
    if (o && o.hasOwnProperty("action")) {
      setSelectedMenuIdx(index);
      if (o.action.route) {
        navigate(o.action.route);
      } else if (o.action.redirect) {
        // note: window.open() returns null if pop-up blocked, e.g. on keypress
        const wnd = window.open(o.action.redirect, "_blank");
        if (wnd) {
          wnd.focus();
        }
      }
    }
  };

  // Handle Up/Down/Enter events
  const [selectedMenuIdx, setSelectedMenuIdx] = React.useState(
    menuOptions.findIndex((o) => o.hasOwnProperty("action"))
  );
  const handleKeyDown = (e) => {
    const n = menuOptions.length;
    const clamp = (i) => ((i % n) + n) % n;
    if (e.key === "ArrowUp") {
      setSelectedMenuIdx((prevIdx) => clamp(prevIdx - 1));
    } else if (e.key === "ArrowDown") {
      setSelectedMenuIdx((prevIdx) => clamp(prevIdx + 1));
    } else if (e.key === "Enter") {
      doAction(selectedMenuIdx);
    }
  };
  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  // Show cursor briefly on load
  const [showLoadingCursor, setShowLoadingCursor] = React.useState(true);
  React.useEffect(() => {
    const id = setTimeout(() => setShowLoadingCursor(false), LOADING_DELAY_MS);
    return () => clearTimeout(id);
  }, [showLoadingCursor]);

  // Optionally fade-out all menu option text EXCEPT SUBTEXT after a delay
  const [isFaded, setIsFaded] = React.useState(false);
  React.useEffect(() => {
    if (fadeOutDelayMs > 0) {
      const id = setTimeout(() => setIsFaded(true), fadeOutDelayMs);
      return () => clearTimeout(id);
    }
    return () => {};
  }, [isFaded, fadeOutDelayMs]);

  const renderMenu = (options, selectedIdx, fade) => {
    const fadeCls = fade ? "bios-menu-fade" : "";
    return (
      <div className="bios-menu-opts-inner">
        {options.map((o) => (
          <span
            key={o.index}
            className={`bios-menu-opt ${
              !o.hasOwnProperty("action") ? "bios-menu-opt-disabled" : ""
            } ${o.index === selectedIdx ? "bios-menu-opt-selected" : ""}`}
            onClick={() => doAction(o.index)}
            onMouseEnter={
              o.hasOwnProperty("action")
                ? () => setSelectedMenuIdx(o.index)
                : null
            }
          >
            <span
              className={`${
                o.menu ? "bios-menu-opt-menu" : "bios-menu-opt-nomenu"
              } ${fadeCls}`}
            >
              ▶
            </span>
            <span>
              <span className={`bios-menu-opt-text ${fadeCls}`}>{o.text}</span>
              &nbsp;&nbsp;
              {o.subtext && (
                <span className="bios-menu-opt-subtext">[{o.subtext}]</span>
              )}
            </span>
          </span>
        ))}
      </div>
    );
  };

  const renderLegend = (legend, fade) => {
    const fadeCls = fade ? "bios-menu-fade" : "";
    return (
      <div className="bios-menu-legend-inner">
        {legend.map((text, index) => (
          <span key={index} className={`"bios-menu-legend-item" ${fadeCls}`}>
            {text}
          </span>
        ))}
      </div>
    );
  };

  const renderFooter = () => {
    const o = menuOptions[selectedMenuIdx];
    return o ? <span>{o.description || MENU_DESCRIPTION_DEFAULT}</span> : null;
  };

  return (
    <div className="bios-menu">
      {showLoadingCursor ? (
        <BootCursor />
      ) : (
        <>
          <div className="bios-menu-title">{MENU_TITLE}</div>
          <div className="bios-menu-contents">
            <div className="bios-menu-opts">
              {renderMenu(
                menuOptions.slice(0, menuSplitIdx),
                selectedMenuIdx,
                isFaded
              )}
              {renderMenu(
                menuOptions.slice(menuSplitIdx),
                selectedMenuIdx,
                isFaded
              )}
            </div>
            <div className="bios-menu-legend">
              {renderLegend(MENU_LEGEND.slice(0, legendSplitIdx), isFaded)}
              {renderLegend(MENU_LEGEND.slice(legendSplitIdx), isFaded)}
            </div>
            <div className="bios-menu-footer">
              <img
                className="bios-logo"
                src={`${process.env.PUBLIC_URL}/logo.png`}
                alt=""
                draggable="false"
              />
              {renderFooter(selectedMenuIdx)}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BiosMenu;
