import React from "react";
import ControlledButtonMenu from "./ControlledButtonMenu";
import useComponentVisible from "../hooks/useComponentVisible.js";

const ButtonMenu = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <ControlledButtonMenu
      menuRef={ref}
      isOpen={isComponentVisible}
      setIsOpen={setIsComponentVisible}
      {...props}
    />
  );
};

export default ButtonMenu;
