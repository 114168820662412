import React from "react";
import {
  Separator,
  MenuListItem,
  Frame,
  Button,
  GroupBox,
  TextInput,
  Handle,
  Checkbox,
} from "react95";
import AppWindow from "../components/AppWindow";
import ButtonMenu from "../components/ButtonMenu";
import PromptDialog from "../components/PromptDialog";
import { defaultBrowserSet, setDefaultBrowserSet } from "../utils/state.js";

const StatusText = Object.freeze({
  NONE: "",
  LOADING: "Connecting...",
  FINISHED: "Done",
});

const InternetExplorer = (props) => {
  const {
    useWayback,
    waybackUrl,
    defaultUrl,
    nonWaybackHostnames,
    onAlert,
    onNewWindow,
    onClose,
  } = props;

  const USE_WAYBACK = useWayback !== false;
  const WAYBACK_HOSTNAME = "web.archive.org";
  const WAYBACK_URL =
    waybackUrl || `https://${WAYBACK_HOSTNAME}/web/19961022173245/`;
  const DEFAULT_URL = defaultUrl || "http://www.geocities.com/";
  const NON_WAYBACK_HOSTNAMES = nonWaybackHostnames || {}; // value is discarded

  // HACK! To force reloading the iframe even if the URL didn't change,
  // we need to randomize the "key" prop on address bar submission.
  const [iframeKey, setIframeKey] = React.useState(0);
  const [iframeUrl, setIframeUrl] = React.useState(DEFAULT_URL);
  const [urlInputValue, setUrlInputValue] = React.useState(iframeUrl);
  const [iframeStatusText, setIframeStatusText] = React.useState(
    iframeUrl ? StatusText.LOADING : StatusText.NONE
  );
  const iframeRef = React.useRef(null);
  const [showStatusBar, setShowStatusBar] = React.useState(true);

  // Show annoying default browser check?
  const [showDefaultBrowserPrompt, setShowDefaultBrowserPrompt] =
    React.useState(!defaultBrowserSet());
  const [alwaysNagDefaultBrowser, setAlwaysNagDefaultBrowser] =
    React.useState(true);

  // Optionally convert all URLs (with some exceptions) into Internet Archive,
  // but hide this from the user in the address bar
  const transformUrl = (url) => {
    const parseUrl = (rawUrl) => {
      let u = null;
      try {
        u = new URL(rawUrl);
      } catch (_) {
        try {
          u = new URL("https://" + rawUrl);
        } catch (__) {}
      }
      return u;
    };

    const parsedUrl = parseUrl(url);
    if (USE_WAYBACK) {
      // Check if already on Wayback or explicitly skipped
      if (parsedUrl) {
        if (
          parsedUrl.hostname === WAYBACK_HOSTNAME ||
          NON_WAYBACK_HOSTNAMES.hasOwnProperty(parsedUrl.hostname)
        ) {
          return parsedUrl.href;
        }
      }
      return WAYBACK_URL + url;
    }
    return parsedUrl ? parsedUrl.href : "about:blank";
  };

  // Icon toolbar items
  const IconToolbar = Object.freeze([
    { spriteIdx: 0, text: "Back" },
    { spriteIdx: 1, text: "Forward" },
    { spriteIdx: 2, text: "Stop" },
    { spriteIdx: 3, text: "Refresh" },
    { spriteIdx: 4, text: "Home" },
    { spriteIdx: 5, text: "Search" },
    { spriteIdx: 6, text: "Favorites", isMenu: true },
    { spriteIdx: 7, text: "Print" },
    { spriteIdx: 8, text: "Font" },
    { spriteIdx: 9, text: "Mail", isMenu: true },
  ]);

  // Address bar event handlers
  const handleUrlInputChange = (e) => setUrlInputValue(e.target.value);
  const handleUrlInputSubmit = (e) => {
    e.preventDefault();
    setIframeStatusText(StatusText.LOADING);
    setIframeUrl(urlInputValue);
    setIframeKey(iframeKey + 1);
  };

  // iframe event handlers
  const handleIframeLoad = () => {
    setIframeStatusText(StatusText.FINISHED);
  };
  const handleIframeError = () => {
    // NOTE: this event doesn't actually trigger :(
    setIframeStatusText(StatusText.NONE);
    onAlert &&
      onAlert(
        "error",
        "Microsoft Internet Explorer",
        `Internet Explorer cannot open the Internet site ${iframeUrl}. ` +
          "An unexpected error has occurred. Restart Internet Explorer, and then try again."
      );
  };

  const renderDoubleHandle = () => {
    return (
      <>
        <Handle size="100%" style={{ width: 4 }} />
        <Handle size="100%" style={{ width: 4, marginRight: 4 }} />
      </>
    );
  };

  const renderMenuIcon = (o, idx) => {
    const spritePosition = -20 /*sprite px*/ * o.spriteIdx;
    // TODO ButtonMenu instead if o.isMenu
    return (
      <Button key={idx} className="iexplore-icon-toolbar-button" variant="menu">
        <span
          className={`iexplore-icon-toolbar-icon ${
            o.isMenu ? "iexplore-icon-toolbar-icon-menu" : ""
          }`}
          style={{ backgroundPosition: `${spritePosition}px 0` }}
        />
        {o.text}
      </Button>
    );
  };

  const renderIconToolbar = () => {
    return (
      <div className="iexplore-icon-toolbar">
        <div className="iexplore-icon-toolbar-left">
          <span style={{ display: "flex", height: "100%" }}>
            {renderDoubleHandle()}
          </span>
          {IconToolbar.map(renderMenuIcon)}
          <span style={{ flexGrow: 1 }} />
          <Separator orientation="vertical" size="100%" />
        </div>
        <div className="iexplore-icon-toolbar-right">
          <img
            className="iexplore-icon-toolbar-logo"
            src={`${process.env.PUBLIC_URL}/icons/iexplore.svg`}
            alt=""
            draggable="false"
          />
        </div>
      </div>
    );
  };

  const renderAddressBar = () => {
    // TODO TextInput is actually Select with TextInput
    return (
      <div className="iexplore-addressbar">
        <span className="iexplore-addressbar-section">
          {renderDoubleHandle()}
          Address
        </span>
        <form
          className="iexplore-addressbar-form"
          onSubmit={handleUrlInputSubmit}
        >
          <TextInput
            style={{ minHeight: "auto", height: 28 }}
            value={urlInputValue}
            onChange={handleUrlInputChange}
            fullWidth
          />
        </form>
        <Separator
          orientation="vertical"
          size="unset"
          style={{ marginLeft: 4 }}
        />
        <span className="iexplore-addressbar-section">
          {renderDoubleHandle()}
          Links
        </span>
      </div>
    );
  };

  const renderStatusBar = () => {
    return (
      <div className="iexplore-status">
        <Frame className="iexplore-status-left" variant="well">
          {iframeStatusText}
        </Frame>
        <Frame className="iexplore-status-mid" variant="well">
          {/* TODO content?? */}
        </Frame>
        <Frame className="iexplore-status-right" variant="well">
          <img
            className="icon iexplore-status-icon"
            src={`${process.env.PUBLIC_URL}/icons/html.png`}
            alt=""
            draggable="false"
          />
        </Frame>
      </div>
    );
  };

  const renderDefaultBrowserPrompt = () => {
    const handleYesNo = (flag) => {
      // Don't show again this session?
      if (flag || !alwaysNagDefaultBrowser) {
        setDefaultBrowserSet(true);
      }
      setShowDefaultBrowserPrompt(false);
    };
    return (
      <PromptDialog
        title="Internet Explorer"
        width={460}
        icon="warn"
        onYes={() => handleYesNo(true)}
        onClose={() => handleYesNo(false)}
        stackIndex={props.stackIndex}
      >
        <div className="iexplore-prompt-text">
          <p>Internet Explorer 3.0 is not currently your default browser.</p>
          <p>Would you like to make it your default browser?</p>
          <div style={{ marginTop: 8, marginBottom: -16 }}>
            <Checkbox
              className="iexplore-prompt-text"
              name="alwayscheck"
              value="alwayscheck"
              checked={alwaysNagDefaultBrowser}
              onChange={() =>
                setAlwaysNagDefaultBrowser(!alwaysNagDefaultBrowser)
              }
              label="Always perform this check when starting Internet Explorer"
            />
          </div>
        </div>
      </PromptDialog>
    );
  };

  if (showDefaultBrowserPrompt) {
    return renderDefaultBrowserPrompt();
  }

  const buttonMenuProps = {
    buttonClass: "window-toolbar-button",
    activeButtonClass: "window-toolbar-active-button",
    buttonProps: { size: "sm", variant: "menu" },
  };

  return (
    <AppWindow
      title="Microsoft Internet Explorer"
      windowIcon="html"
      windowStyle={{ display: "flex", flexDirection: "column" }}
      width={800}
      height={560}
      contentStyle={{ display: "flex", flex: "1 1 auto", padding: "0 0 2px" }}
      toolbar={
        <>
          <ButtonMenu buttonText="File" {...buttonMenuProps}>
            <MenuListItem
              size="sm"
              disabled={!onNewWindow}
              onClick={onNewWindow}
            >
              New Window
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Open…
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Save
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Save As File…
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              New Message…
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Send To
              <span className="window-toolbar-list-arrow" />
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Page Setup…
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Print…
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Create Shortcut
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Properties
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" onClick={onClose}>
              Close
            </MenuListItem>
          </ButtonMenu>
          <ButtonMenu buttonText="Edit" {...buttonMenuProps}>
            <MenuListItem size="sm" disabled>
              Cut
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Copy
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Paste
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Select All
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Find (on this page)…
            </MenuListItem>
          </ButtonMenu>
          <ButtonMenu buttonText="View" {...buttonMenuProps}>
            <MenuListItem size="sm" disabled>
              <span className="window-toolbar-list-checkmark" />
              Toolbar
            </MenuListItem>
            <MenuListItem
              size="sm"
              onClick={() => setShowStatusBar(!showStatusBar)}
            >
              {showStatusBar && (
                <span className="window-toolbar-list-checkmark" />
              )}
              Status Bar
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Fonts
              <span className="window-toolbar-list-arrow" />
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Stop
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Refresh
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Source
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Options…
            </MenuListItem>
          </ButtonMenu>
          <ButtonMenu buttonText="Go" {...buttonMenuProps}>
            <MenuListItem size="sm" disabled>
              Back
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Forward
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              {/* http://home.microsoft.com/ */}
              Start Page
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              {/* http://home.microsoft.com/access/allinone.asp */}
              Search the Web
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              {/* http://home.microsoft.com/best/best.asp */}
              Best of the Web
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Read Mail
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Read News
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              {/* <span className="window-toolbar-list-checkmark" /> */}1
              C:\WINDOWS\SYSTEM\BLANK.HTM
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Open History Folder
            </MenuListItem>
          </ButtonMenu>
          <ButtonMenu buttonText="Favorites" {...buttonMenuProps}>
            <MenuListItem size="sm" disabled>
              Add To Favorites…
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              Organize Favorites…
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              (Empty)
            </MenuListItem>
          </ButtonMenu>
          <ButtonMenu buttonText="Help" {...buttonMenuProps}>
            <MenuListItem size="sm" disabled>
              Help Topics
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              {/* http://home.microsoft.com/tutorial/default.html */}
              Web Tutorial
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              Microsoft on the Web
              <span className="window-toolbar-list-arrow" />
            </MenuListItem>
            <MenuListItem size="sm" disabled>
              {/* http://www.microsoft.com/ie/support */}
              Online Support
            </MenuListItem>
            <Separator size="unset" />
            <MenuListItem size="sm" disabled>
              About Internet Explorer
            </MenuListItem>
          </ButtonMenu>
        </>
      }
      preFooter={showStatusBar ? renderStatusBar() : null}
      {...props}
    >
      <div className="iexplore-content">
        <GroupBox className="iexplore-toolbar2">
          {renderIconToolbar()}
          <Separator size="unset" />
          {renderAddressBar()}
        </GroupBox>
        <Frame className="iexplore-iframe-container" variant="well">
          <iframe
            ref={iframeRef}
            key={iframeKey}
            className="iexplore-iframe"
            title="iexplore"
            src={transformUrl(iframeUrl)}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
            allowFullScreen="allowfullscreen"
            sandbox="allow-same-origin allow-scripts allow-forms allow-pointer-lock allow-modals allow-popups"
          />
        </Frame>
      </div>
    </AppWindow>
  );
};

export default InternetExplorer;
