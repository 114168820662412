import React from "react";
import BootCursor from "./BootCursor.js";

const SPLASH_MS = 6144 /* boot.mp3 */ + 800; /* buffer */
const BLACK_SCREEN_MS = 800;

const SplashScreen = (props) => {
  const { onComplete } = props;

  // Show boot logo and then quick black screen
  const [splashDone, setSplashDone] = React.useState(false);
  React.useEffect(() => {
    if (!splashDone) {
      const id = setTimeout(() => setSplashDone(true), SPLASH_MS);
      return () => clearTimeout(id);
    } else {
      const id = setTimeout(() => onComplete && onComplete(), BLACK_SCREEN_MS);
      return () => clearTimeout(id);
    }
  }, [splashDone, onComplete]);

  return !splashDone ? (
    <div className="boot-splash">
      <audio src={`${process.env.PUBLIC_URL}/win/boot.mp3`} autoPlay />
    </div>
  ) : (
    <BootCursor />
  );
};

export default SplashScreen;
