import React from "react";
import AppWindow from "../components/AppWindow";
import { dispatchIframeEvents } from "../utils/iframe.js";

const Paint = (props) => {
  const { loadImage, onEvent, onClose } = props;
  const iframeRef = React.useRef(null);

  const handleIframeLoad = () => {
    // Forward events up (otherwise swallowed by iframe)
    dispatchIframeEvents(iframeRef.current, onEvent);

    // jspaint API hooks
    if (iframeRef && iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.close = () => {
        onClose && onClose();
      };
    }
  };

  // Create image path (NOTE: relative to /jspaint/ subdirectory!!)
  let imgPath = "";
  if (loadImage) {
    imgPath = "#load:../" + loadImage;
  }

  return (
    <AppWindow
      windowIcon="mspaint"
      width={770}
      height={500}
      contentStyle={{ padding: 0 }}
      {...props}
    >
      <div style={{ display: "flex", flex: "1 1 auto" }}>
        <iframe
          ref={iframeRef}
          title="mspaint"
          src={`${process.env.PUBLIC_URL}/jspaint/index.html${imgPath}`}
          style={{ minWidth: 0, minHeight: 0, flex: "1 1 auto" }}
          onLoad={handleIframeLoad}
        />
      </div>
    </AppWindow>
  );
};

export default Paint;
