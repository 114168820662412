import React from "react";
import BiosScreen from "./boot/BiosScreen.js";
import BootCursor from "./boot/BootCursor.js";
import SplashScreen from "./boot/SplashScreen.js";
import { setBootComplete } from "./utils/state.js";
import { SHOULD_BLOCK_CONTEXT_MENU } from "./utils/constants.js";

const Boot = (props) => {
  const { onBootChange, shutdownAction } = props;
  const [biosDone, setBiosDone] = React.useState(false);
  const [isStartedFromShutdown, setIsStartedFromShutdown] =
    React.useState(false);

  // When in shutdown state, press any key to start
  // (see ShutdownDialog.ShutdownOptions; shutdownAction === 1)
  React.useEffect(() => {
    const handleClickToStart = () => setIsStartedFromShutdown(true);
    const handleKeyDownToStart = (event) => {
      if (
        event &&
        !event.ctrlKey &&
        !event.shiftKey &&
        !event.altKey &&
        !event.metaKey
      ) {
        setIsStartedFromShutdown(true);
      }
    };
    document.addEventListener("click", handleClickToStart, true);
    document.addEventListener("keydown", handleKeyDownToStart, true);
    return () => {
      document.removeEventListener("click", handleClickToStart, true);
      document.removeEventListener("keydown", handleKeyDownToStart, true);
    };
  }, []);

  const handleBiosDone = () => {
    setBiosDone(true);
  };

  const handleSplashDone = () => {
    setBootComplete(true);
    onBootChange && onBootChange(true);
  };

  const blockContextMenu = SHOULD_BLOCK_CONTEXT_MENU
    ? (e) => e.preventDefault()
    : null;

  return (
    <div className="boot-screen" onContextMenu={blockContextMenu}>
      {shutdownAction === 1 && !isStartedFromShutdown ? (
        <div className="boot-term">
          <p>Press any key to boot...</p>
          <BootCursor noWrapper={true} />
        </div>
      ) : !biosDone ? (
        <BiosScreen onComplete={handleBiosDone} cpuSpeed={1} />
      ) : (
        <SplashScreen onComplete={handleSplashDone} />
      )}
    </div>
  );
};

export default Boot;
