import React from "react";
import ControlledButtonMenu from "./ControlledButtonMenu.js";
import StartMenuEntry from "./StartMenuEntry.js";
import useComponentVisible from "../hooks/useComponentVisible.js";
import {
  CMDS,
  DOCUMENTS,
  getBasenameForFile,
  getIconForFile,
} from "../Files.js";

/*
START_MENU "Entry" fields:
- icon: str              // filename in "icons" dir without .png
- name: str              // displayed text
- divider: bool          // with no other fields
- emptyFolder: bool      // with no other fields
- UNION[
  cmd: str               // in CMDS
  folder: Array<Entry>   // only 1 level supported for now
]
*/
const START_MENU = [
  {
    icon: "programs",
    name: "Programs",
    folder: [
      {
        icon: "explore",
        name: "Internet Explorer",
        cmd: CMDS.IEXPLORE,
      },
      {
        icon: "notepad",
        name: "Notepad",
        cmd: CMDS.NOTEPAD,
      },
      {
        icon: "mspaint",
        name: "Paint",
        cmd: CMDS.MSPAINT,
      },
      {
        icon: "winmine",
        name: "Minesweeper",
        cmd: CMDS.MINESWEEPER,
      },
      {
        icon: "sol2",
        name: "Solitaire",
        cmd: CMDS.SOLITAIRE,
      },
    ],
  },
  {
    icon: "documents",
    name: "Documents",
    folder: DOCUMENTS.map((f) => ({
      icon: getIconForFile(f),
      name: getBasenameForFile(f, true),
      cmd: CMDS.OPEN,
      args: [f],
    })),
  },
  {
    icon: "settings",
    name: "Settings",
    folder: [
      /* unimplemented */
      {
        icon: "controlpanel",
        name: "Control Panel",
        cmd: "",
        disabled: true,
      },
      {
        icon: "printsettings",
        name: "Printers",
        cmd: "",
        disabled: true,
      },
      {
        icon: "taskbar",
        name: "Taskbar…",
        cmd: "",
        disabled: true,
      },
    ],
  },
  {
    icon: "find",
    name: "Find",
    folder: [
      /* unimplemented */
      {
        icon: "find2",
        name: "Files or Folders…",
        cmd: "",
        disabled: true,
      },
      {
        icon: "findcomputer",
        name: "Computer…",
        cmd: "",
        disabled: true,
      },
    ],
  },
  {
    icon: "helpbook",
    name: "Help",
    cmd: CMDS.HELP,
  },
  {
    icon: "run",
    name: "Run\u2026",
    cmd: CMDS.RUN,
  },
  {
    divider: true,
  },
  {
    icon: "computer",
    name: "Shut Down\u2026",
    cmd: CMDS.SHUTDOWN,
    delay: 0,
  },
];

const StartMenu = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const { onCmd } = props;

  const handleCmd = (cmd, args, delay) => {
    setIsComponentVisible(false);
    onCmd(cmd, args, delay);
  };

  return (
    <div ref={ref}>
      <ControlledButtonMenu
        isOpen={isComponentVisible}
        setIsOpen={setIsComponentVisible}
        keepOpenAfterInnerClick={true}
        flipped={true}
        menuClass="start-menu"
        buttonClass="start-menu-button"
        buttonText={
          <>
            <img
              className="start-menu-icon large-icon"
              src={`${process.env.PUBLIC_URL}/icons/logo.png`}
              alt=""
              draggable="false"
            />
            <span className="start-menu-text">Start</span>
          </>
        }
      >
        {START_MENU.map((entry, idx) => (
          <StartMenuEntry
            key={idx}
            entry={entry}
            isSubEntry={false}
            onCmd={handleCmd}
          />
        ))}
      </ControlledButtonMenu>
    </div>
  );
};

export default StartMenu;
