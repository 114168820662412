import React from "react";
import { Frame } from "react95";

// TODO handle actions
const DOCK_ITEMS = [
  {
    icon: "sound",
  },
];

const Dock = (props) => {
  const [date, setDate] = React.useState(new Date());

  React.useEffect(() => {
    const id = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(id);
  });

  const dateStr = date.toLocaleTimeString([], { timeStyle: "short" });

  return (
    <Frame className="appbar-dock" variant="well">
      {DOCK_ITEMS.map((entry, idx) => (
        <img
          key={idx}
          className="small-icon"
          src={`${process.env.PUBLIC_URL}/icons/${entry.icon}.png`}
          alt=""
          draggable="false"
        />
      ))}
      <span>{dateStr}</span>
    </Frame>
  );
};

export default Dock;
