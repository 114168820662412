// Artificially delay program loading to simulate slow PCs (0 to disable)
export const SPAWN_DELAY_MS = 600;

// Idle time before rendering the screensaver (0 to disable)
export const SCREENSAVER_IDLE_MS = 60_000;

// Block right-click context menus on certain core elements?
export const SHOULD_BLOCK_CONTEXT_MENU = true;

// Time after which non-relevant BIOS menu options will disappear (0 to disable)
export const BIOS_MENU_FADE_OUT_DELAY_MS = 3000;

// Save The Date URL
export const SAVE_THE_DATE_URL = "https://docs.google.com/forms/d/e/1FAIpQLSegn9ea1ZCdOMOWI1RjcvuJk0FTlUREerM1O9_5IlMJn37OVQ/viewform";

// RSVP URL
export const RSVP_URL = "https://docs.google.com/forms/d/e/1FAIpQLSe7RjindMAc_APJyAmqnAA5GB1XcXQhf2xh0qQsoT9d8eV8iA/viewform";
